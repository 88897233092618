import { AccountInfo, InteractionType } from '@azure/msal-browser';
import { Client, GraphRequestCallback } from '@microsoft/microsoft-graph-client';
import {
  AuthCodeMSALBrowserAuthenticationProvider,
  AuthCodeMSALBrowserAuthenticationProviderOptions,
} from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';

import {
  fetchMSALAccount, logMSApiEvent, refreshMSALToken, scopes,
} from '../util-helpers/msal';
import msalPublicClientAppInstance from '../util-helpers/msalClient';
import { IMSOAttachmentItem } from './models/outlookItem';

export const msGraphClient = (account: AccountInfo) => {
  const options: AuthCodeMSALBrowserAuthenticationProviderOptions = {
    account,
    scopes,
    interactionType: InteractionType.Silent,
  };
  return Client.initWithMiddleware({
    authProvider: new AuthCodeMSALBrowserAuthenticationProvider(
      msalPublicClientAppInstance,
      options,
    ),
  });
};

export const graphUrl = 'https://graph.microsoft.com/v1.0/';

const graphFetch = async (
  path: string,
  verb: 'GET'|'POST'|'PUT'|'PATCH'|'DELETE',
  content?: string,
  callback?: GraphRequestCallback,
) => {
  await refreshMSALToken(true);
  const activeAccount = await fetchMSALAccount();

  if (activeAccount) {
    const graph = msGraphClient(activeAccount);
    const iid = 'IdType="ImmutableId"';
    logMSApiEvent('Attempt', 'MSGraphCall');
    try {
      let res;
      switch (verb) {
        case 'GET':
          res = await graph.api(`${graphUrl}${path}`).header('Prefer', iid).get();
          break;
        case 'POST':
          res = await graph.api(`${graphUrl}${path}`).header('Prefer', iid).post(content, callback);
          break;
        case 'PUT':
          res = await graph.api(`${graphUrl}${path}`).header('Prefer', iid).put(content);
          break;
        case 'PATCH':
          res = await graph.api(`${graphUrl}${path}`).header('Prefer', iid).patch(content);
          break;
        case 'DELETE':
          res = await graph.api(`${graphUrl}${path}`).header('Prefer', iid).delete();
          break;
        default:
          throw new Error('Error unsupported request type');
      }

      logMSApiEvent('Success', 'MSGraphCall');
      return res;
    } catch (error) {
      logMSApiEvent(`Fetch ${error}`, 'MSGraphCall');
      throw new Error(`Graph fetch: ${error}`);
    }
  }

  throw new Error('Error on MS Graph Authentication, No account');
};

export const attachmentUpload = async (
  endpoint: string,
  { name, content, size }: IMSOAttachmentItem,
) => {
  const maxSize = 3000000;
  if (size < maxSize) {
    const reqBody = {
      '@odata.type': '#microsoft.graph.fileAttachment',
      name,
      contentBytes: content,
    };

    return graphFetch(`/me/messages/${endpoint}/attachments`, 'POST', JSON.stringify(reqBody));
  }

  // Handle large file upload
  // https://learn.microsoft.com/en-us/graph/outlook-large-attachments?tabs=javascript
  const activeAccount = await fetchMSALAccount();
  if (activeAccount) {
    const graph = msGraphClient(activeAccount);
    try {
      const reqBody = {
        AttachmentItem: {
          attachmentType: 'file',
          name,
          size: content.length,
        },
      };
      const session = await graph.api(`/me/messages/${endpoint}/attachments/createUploadSession`).post(reqBody);

      const chunkCount = Math.ceil(content.length / maxSize);
      let bytesRemaining = size;
      for (let i = 0; i < chunkCount; i += 1) {
        const step = (i + 1) * maxSize;
        const from = i * maxSize;
        const to = bytesRemaining > maxSize ? step - 1 : content.length - 1;
        bytesRemaining = Math.max(content.length - step, 0);

        // These need to be run in order before moving on the the next
        // eslint-disable-next-line no-await-in-loop
        const res = await fetch(session.uploadUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream',
            'Content-Range': `bytes ${from}-${to}/${content.length}`,
          },
          body: content.slice(from, to + 1),
        });

        // If any one step fails stop
        if (!res.ok) {
          logMSApiEvent(`Large File Upload Error, ${res.status} ${res.statusText}`, 'MSGraphCall');
          throw new Error('Graph error uploading large file');
        }

        // The final content upload returns 201 vs 200
        if (res.status === 201) {
          return true;
        }
      }

      throw new Error('Graph Large File Upload failed to complete.');
    } catch (error) {
      logMSApiEvent(`File Upload ${error}`, 'MSGraphCall');
      throw new Error(`Graph error fetching: ${error}`);
    }
  }

  throw new Error('Error on MS Graph Upload, No account');
};

export default graphFetch;
