import gql from 'graphql-tag';
import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client/errors/ApolloError';

interface IBillingSettings {
  isBillingEnabled: boolean;
  saveAsDraftDefault: boolean;
  saveAsDraftEnabled: boolean;
}

interface IBillingSettingsResponse {
  billingSettings: IBillingSettings;
}

export interface IBillingSettingsPayload {
  getBillingSettings: VoidFunction;
  called: boolean;
  loading: boolean;
  error?: ApolloError;
  billingSettings?: IBillingSettings;
}

export const BILLING_SETTINGS_QUERY = gql`
  query billingSettingsQuery {
    billingSettings
      @rest(type: "billingSettings", path: "/billing/org/settings") {
        isBillingEnabled
        saveAsDraftDefault
        saveAsDraftEnabled
      }
  }`;

const useQueryBillingSettings = (): IBillingSettingsPayload => {
  const [executeQuery, {
    data, error, loading, called,
  }] = useLazyQuery<IBillingSettingsResponse>(
    BILLING_SETTINGS_QUERY, { notifyOnNetworkStatusChange: true, fetchPolicy: 'cache-and-network' },
  );

  const getBillingSettings = useCallback(executeQuery, [executeQuery]);

  return {
    getBillingSettings,
    called,
    loading,
    error,
    // If a 2nd call was made that errors we don't want to return the old data
    billingSettings: (!error && data) ? data.billingSettings : undefined,
  };
};

export default useQueryBillingSettings;
