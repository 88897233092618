import Button from '@fv-components/button';
import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import FilevineIcon from '../../FilevineIcon';

const css = require('./GenericCard.module.scss');

interface GenericCardProps {
  buttonText?: string;
  buttonIcon?: React.ReactElement<typeof FilevineIcon>;
  child: React.ReactElement;
  outlined?: boolean;
  onClose?: VoidFunction;
  onOpen?: VoidFunction;
  close?: boolean;
  buttonTitle?: string;
  genCardButtonClass?: string;
  dataTestUniq?: string;
}

const GenericCard: FC<GenericCardProps> = (
  {
    buttonIcon,
    buttonText,
    child,
    outlined = true,
    onClose,
    onOpen,
    close,
    buttonTitle,
    genCardButtonClass,
    dataTestUniq,
  }: GenericCardProps,
) => {
  const [isCardShowing, setIsCardShowing] = useState(close || false);

  const handleShowCard = () => {
    setIsCardShowing(true);
    if (onOpen) onOpen();
  };

  const handleCloseCard = useCallback(() => {
    setIsCardShowing(false);
    if (onClose) onClose();
  }, [onClose]);

  useEffect(() => {
    if (close) handleCloseCard();
  }, [close, handleCloseCard]);

  const page = (
    <div className={css.card}>
      <Button
        className={css.backButton}
        data-test={dataTestUniq ? `genericCardBack ${dataTestUniq}` : 'genericCardBack'}
        onClick={handleCloseCard}
        onKeyDown={handleCloseCard}
        title="Back"
      >
        <FilevineIcon icon="chevron-left" />
        &nbsp;Back
      </Button>
      {child}
    </div>
  );

  const button = (
    <Button
      data-test={dataTestUniq ? `genericCardOpen ${dataTestUniq}` : 'genericCardOpen'}
      outlined={outlined}
      className={genCardButtonClass ? `${css.cardButton} ${genCardButtonClass}` : css.cardButton}
      onClick={handleShowCard}
      onKeyDown={handleShowCard}
      title={buttonTitle}
    >
      {buttonIcon}
      {buttonText}
    </Button>
  );

  return (isCardShowing ? page : button);
};

export default GenericCard;
