import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import gql from 'graphql-tag';

interface IImage {
    ContentType: string;
    Image: string;
}

interface IImageResponse {
    image?: IImage;
}

export interface IImagePayload {
    called: boolean;
    loading: boolean;
    error?: ApolloError;
    image?: IImage;
}

export interface IQueryImageHook {
  imageResult: IImagePayload;
  getImage: (imageId: string) => void;
}

export const IMAGE_QUERY = gql`
  query imageQuery {
    image(id: $id)
      @rest(type: "Image", path: "{args.id}") {
        ContentType,
        Image,
    }
  }`;

const useQueryImage = (): IQueryImageHook => {
  const [executeQuery, {
    data, error, loading, called,
  }] = useLazyQuery<IImageResponse>(
    IMAGE_QUERY, { notifyOnNetworkStatusChange: true },
  );

  const getImage = useCallback(
    (id: string) => executeQuery({ variables: { id } }), [executeQuery],
  );

  return {
    imageResult: {
      called,
      error,
      loading,
      image: data?.image,
    },
    getImage,
  };
};

export default useQueryImage;
