import React, { useContext } from 'react';
import FilevineIcon from '../../../../Taskpane/FilevineIcon';
import ProjectContext from '../../../../Taskpane/ProjectContext';
import { IFolder } from '../../../../util-api/useQueryFolderList';

const css = require('./DestinationFolder.module.scss');

export interface IDestinationFolder {
  currentlySelectedFolder: IFolder | undefined;
  getParentFolder: (parentKey: number) => IFolder;
}

const DestinationFolder = ({ currentlySelectedFolder, getParentFolder }: IDestinationFolder) => {
  const { currentProjectData } = useContext(ProjectContext);

  const getParentName = () => getParentFolder(
    currentlySelectedFolder?.parentId?.native || 0,
  ).name;

  return (
    <div className={css.destinationFolder}>
      {/* FOLDER BREADCRUMB SECTION */}
      {currentlySelectedFolder
        ? (
          <div className={css.folderBreadcrumbSectionHighlighted}>
            <div className={css.breadCrumbs}>
              {/* Project Root */}
              <span
                className={css.folderLevel}
                data-test="projectName"
              >
                {currentProjectData
                  && currentProjectData?.projectOrClientName?.length > 10
                  ? `${currentProjectData?.projectOrClientName.slice(0, 10).trimEnd()}...`
                  : currentProjectData?.projectOrClientName}
                <FilevineIcon icon="chevron-right" className={css.icon} />
              </span>
              {/* Parent folder */}
              {currentlySelectedFolder.parentId?.native
                !== currentProjectData?.rootDocFolderId?.native
                && (
                  <>
                    <span
                      className={css.folderLevel}
                    >
                      {getParentName().length > 10 ? `${getParentName().slice(0, 10).trimEnd()}...` : getParentName() || 'Parent'}
                      <FilevineIcon icon="chevron-right" className={css.icon} />
                    </span>
                  </>
                )}
              {/* Current Folder */}
              <span className={css.folderLevel} data-test="folderPlaceholder">
                {currentlySelectedFolder.name}
              </span>
            </div>
          </div>
        )
        : (
          <div className={css.folderBreadcrumbSection} data-test="projectName">
            {currentProjectData?.projectOrClientName}
          </div>
        )}
    </div>
  );
};

export default DestinationFolder;
