import React, {
  FC, useContext, useEffect, useState,
} from 'react';
import useQueryProjectContacts from '../../../util-api/useQueryProjectContacts';
import { getIsMeeting } from '../../../util-helpers/office';
import useDebounce from '../../../util-hooks/useDebounce';
import useInput from '../../../util-hooks/useInput';
import FilevineIcon from '../../FilevineIcon';
import SearchLimitWarning from '../../SearchLimitWarning';
import EmailLink from '../EmailLink';
import GenericCard from '../GenericCard';
import Persona from '../Persona';
import SecondarySearchBar from '../SecondarySearchBar';
import SnackbarContext from '../../../Snackbar/SnackbarContext';
import CreateContact from '../../CreateContact';
import { IOrgPerson } from '../../CreateContact/CreateContact';
import IProjectContact from '../../../util-api/models/projectContact';
import ContactPreview from '../ContactPreview';

const css = require('./Contacts.module.scss');

interface IContactsProps {
  projectId?: number;
  projectEmail?: string;
  isActiveTab: boolean;
}

const Contacts: FC<IContactsProps> = ({
  isActiveTab,
  projectId,
  projectEmail,
}: IContactsProps) => {
  // Search and fetch
  const [values, handleValueChange] = useInput({ contactSearchTerm: '' });
  const searchPlaceholderText = 'Search contacts by name or email.';
  const isMeeting = getIsMeeting();

  const { addMessage: addSnackbarMessage } = useContext(SnackbarContext);

  const {
    getProjectContacts,
    projectContactsSearchResults: {
      contacts, error, loading, called,
    },
  } = useQueryProjectContacts();

  const debouncedContactSearchTerm = useDebounce(values.contactSearchTerm, 300).trimStart();

  useEffect(() => {
    if (projectId && isActiveTab) {
      const reqParams = {
        projectId,
        searchTerm: debouncedContactSearchTerm,
        limit: 1000,
      };
      getProjectContacts(
        reqParams.projectId,
        reqParams.searchTerm,
        reqParams.limit,
      );
    }
  }, [projectId, debouncedContactSearchTerm, getProjectContacts, isActiveTab]);

  const [closeCard, setCloseCard] = useState(false);

  // NOTE: this is needed to conditionally toggle the position: sticky; style
  // on the Contacts Header, since the GenericCard renders as a child of the
  // contacts header and the sticky positioning of the parent element can
  // mess with the styles and positioning of the child, GenericCard
  const [stickyHeader, setStickyHeader] = useState(true);

  const closeCreateContact = () => {
    if (closeCard) setCloseCard(false);
    if (!stickyHeader) setStickyHeader(true);
    getProjectContacts(projectId!, values.contactSearchTerm = '', 1000);
  };

  return (
    <div data-test="contacts-container">
      <div className={css.contactsHeader} style={!stickyHeader ? { position: 'unset' } : undefined}>
        {projectEmail && (
          <div className={css.projectEmail}>
            <EmailLink
              displayName={projectEmail}
              emailAddress={projectEmail}
              emailField={!isMeeting ? 'cc' : 'optionalAttendees'}
            />
          </div>
        )}

        <div className={css.contactsSplit}>
          <div className={css.contactsSearch}>
            <SecondarySearchBar
              searchInputName="contactSearchTerm"
              searchPlaceholderText={searchPlaceholderText}
              searchTerm={values.contactSearchTerm}
              handleSearchTermChange={handleValueChange}
              loading={loading}
            />
          </div>
          {projectId && (
            <div className={css.contactsCreateWrapper}>
              <GenericCard
                outlined={false}
                buttonIcon={<FilevineIcon icon="user-plus" />}
                buttonTitle="Create a Contact"
                onClose={closeCreateContact}
                close={closeCard}
                onOpen={() => setStickyHeader(false)}
                genCardButtonClass={css.createProjectContactGenCard}
                dataTestUniq="createProjectContact"
                child={(
                  <CreateContact
                    formTitle="Create a Contact"
                    cta="Create Contact"
                    projectId={projectId}
                    onSuccess={(person: IOrgPerson) => {
                      setCloseCard(true);
                      addSnackbarMessage(`Contact Created: ${person.fullName}`);
                    }}
                  />
                )}
              />
            </div>
          )}
        </div>
      </div>
      {contacts && contacts.hasMore && (
      <SearchLimitWarning limit={contacts.limit} />
      )}

      {(contacts?.count && !error) ? contacts.items.map((projectContact: IProjectContact) => {
        const key = projectContact?.projectContactId?.native
        || projectContact?.orgContactId?.native;
        return (
          <div key={key} data-test={key}>
            <ContactPreview projectContact={projectContact} />
            <hr className={css.contactsSeparator} />
          </div>
        );
      }) : (called && !loading && <div className={css.contactsNoResults}>No contacts found</div>)}
      {!contacts && loading && <div className={css.contactsLoading}><Persona loading small /></div>}
    </div>
  );
};

export default Contacts;
