import React, {
  FC, useContext, useEffect,
} from 'react';
import IProjectContact from '../../../util-api/models/projectContact';

import useQueryBillingSettings from '../../../util-api/useQueryBillingSettings';
import useQueryProjectClient from '../../../util-api/useQueryProjectClient';
import useQueryProjectVitals from '../../../util-api/useQueryProjectVitals';

import FilevineIcon from '../../FilevineIcon';
import ProjectContext from '../../ProjectContext';
import BillingTimer from '../BillingTimer';
import CollapsibleSection from '../CollapsibleSection';
import ContactPreview from '../ContactPreview';
import GenericCard from '../GenericCard';
import Persona from '../Persona';
import Vitals from '../Vitals';

const css = require('./ProjectHeader.module.scss');

const ProjectHeader: FC = () => {
  const { currentProjectId, currentProjectData } = useContext(ProjectContext);

  // Get org billing settings
  const {
    getBillingSettings,
    called: billingCalled,
    loading: billingLoading,
    error: billingError,
    billingSettings,
  } = useQueryBillingSettings();
  useEffect(() => {
    getBillingSettings();
  }, [getBillingSettings]);

  // Get vitals data when project changes
  const { getProjectVitals, projectVitalsSearchResult: { vitals } } = useQueryProjectVitals();
  useEffect(() => {
    if (currentProjectId) {
      getProjectVitals(currentProjectId);
    }
  }, [currentProjectId, getProjectVitals]);

  // Get client data when project changes
  const {
    getProjectClient,
    projectClientSearchResults: {
      client,
      error: clientError,
      loading: clientLoading,
    },
  } = useQueryProjectClient();
  useEffect(() => {
    if (currentProjectId) {
      getProjectClient(currentProjectId);
    }
  }, [currentProjectId, getProjectClient]);

  return (
    <>
      {clientLoading
        && <div className={css.projectContactLoading}><Persona loading small /></div>}
      {client
        && client.personId
        && !clientError
        && (
          <ContactPreview
            projectContact={
              {
                orgContact: client, role: 'Client', orgContactId: {}, projectContactId: {},
              } as IProjectContact
            }
          />
        )}
      {currentProjectId
        && billingCalled
        && !billingError
        && !billingLoading
        && billingSettings?.isBillingEnabled
        && (
        <GenericCard
          outlined={false}
          buttonText=" Billing"
          buttonIcon={<FilevineIcon icon="clock-o" />}
          child={(
            <BillingTimer
              projectId={currentProjectId}
              isDraftEnabled={billingSettings?.saveAsDraftEnabled}
              isDraftDefault={billingSettings?.saveAsDraftDefault}
            />
          )}
        />
        )}
      <CollapsibleSection
        collapsed
        title="Vitals"
        icon="heartbeat"
        content={(
          <Vitals
            vitals={vitals}
            phaseName={currentProjectData ? currentProjectData.phaseName : undefined}
          />
        )}
      />
    </>
  );
};

export default ProjectHeader;
