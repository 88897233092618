import React from 'react';
import IProjectContact from '../../../util-api/models/projectContact';
import FilevineIcon from '../../FilevineIcon';
import ContactDetails from '../ContactDetails';
import GenericCard from '../GenericCard';
import Persona, { getInitials } from '../Persona';

const css = require('./ContactPreview.module.scss');

interface IContactPreviewProps {
  projectContact: IProjectContact,
}
const ContactPreview = ({ projectContact }: IContactPreviewProps) => (
  <div className={css.container}>
    <div className={css.persona}>
      <Persona
        avatarUrl={projectContact?.orgContact?.pictureUrl}
        initials={getInitials(projectContact?.orgContact?.fullName)}
      />
    </div>
    <div className={css.text}>
      <div className={css.fullname}>
        {projectContact?.orgContact?.fullName || ''}
      </div>
      {projectContact?.orgContact?.primaryEmail && (
        <div className={css.email}>
          {projectContact?.orgContact?.primaryEmail || ''}
        </div>
      )}
      {projectContact?.orgContact?.phones?.length > 0 && (
        <div className={css.phone}>
          {projectContact?.orgContact?.phones[0]?.number || ''}
        </div>
      )}
    </div>
    <div className={css.cardButton}>
      <GenericCard
        child={<ContactDetails projectContact={projectContact} />}
        buttonIcon={<FilevineIcon icon="chevron-right" />}
        outlined={false}
      />
    </div>
  </div>
);

export default ContactPreview;
