import { useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { ExecutionResult } from 'graphql';

export interface IDocUploadURL {
  contentType: string;
  documentId: { native: number; };
  url: string;
}

export interface IDocDataReq {
  projectId: { native: number; };
  folderId: { native: number; };
  filename: string;
  size: number;
}

export interface ICreateDocPayload {
  addDocument: (payload: IDocDataReq) => Promise<ExecutionResult>;
  loading: boolean;
  error?: ApolloError;
  called: boolean;
  docUploadURL?: IDocUploadURL;
}

export const ADD_DOCUMENT = gql`
  mutation AddDocument ( $docData: IDocDataReq! ) {
    docUploadURL ( document: $docData )
      @rest(
        type: "Document",
        path: "/documents",
        method: "POST",
        bodyKey: "document"
      ) {
        contentType
        documentId
        url
      } 
  }`;

const useCreateDoc = (): ICreateDocPayload => {
  const [executeQuery, {
    data,
    error,
    loading,
    called,
  }] = useMutation<
    { docUploadURL: IDocUploadURL; }, { docData: IDocDataReq; }
  >(
    ADD_DOCUMENT, { notifyOnNetworkStatusChange: true },
  );

  const addDocument = useCallback(
    (docData: IDocDataReq) => executeQuery(
      { variables: { docData } },
    ), [executeQuery],
  );

  return {
    addDocument,
    error,
    loading,
    called,
    docUploadURL: (!error && data) ? data.docUploadURL : undefined,
  };
};

export default useCreateDoc;
