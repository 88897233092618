import React, {
  FC, useCallback, useContext, useEffect, useState,
} from 'react';
import Button from '@fv-components/button';
import LinearProgress from '@fv-components/linear-progress';
import DocumentContext from '../DocumentContext';
import ProjectContext from '../../../Taskpane/ProjectContext';
import SaveAsContext from './SaveAsContext';
import { IFolder } from '../../../util-api/useQueryFolderList';
import { IDocument } from '../../../util-api/models/document';
import FolderPicker from './FolderPicker';
import FilenameInput from './FilenameInput';
import useCreateDoc from '../../../util-api/useCreateDoc';
import useMutateDocAttachToProject from '../../../util-api/useMutateDocAttachToProject';
import useMutateDocSaveAsRevision from '../../../util-api/useMutateDocSaveAsRevision';
import GenericCard from '../../../Taskpane/ProjectWorkspace/GenericCard';
import FilevineIcon from '../../../Taskpane/FilevineIcon';
import DestinationFolder from './DestinationFolder';

const css = require('./SaveAs.module.scss');

interface FilenameErrors {
  hasErrors: boolean;
  errorMessage: string;
}

const SaveAs: FC = () => {
  const { currentProjectId, currentProjectData } = useContext(ProjectContext);
  const rootDocFolderId = currentProjectData?.rootDocFolderId;
  const rootFolderId = rootDocFolderId?.native || 0;
  const {
    currentDoc,
    setCurrentDocument,
  } = useContext(DocumentContext);
  const {
    currentFolder,
    extension,
    filename,
    parentFolders,
    setCurrentFolder,
    setExtension,
    setFilename,
    setParentFolders,
  } = useContext(SaveAsContext);
  const [folderDestination, setFolderDestination] = useState<number>(rootFolderId);

  const [errors, setErrors] = useState<FilenameErrors>({ hasErrors: false, errorMessage: '' });
  const [isSavingDoc, setIsSavingDoc] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorOnSave, setErrorOnSave] = useState('');
  const [inputKey, setInputKey] = useState('');

  if (!currentDoc?.filename && !isSavingDoc) {
    Office.context.document.getFilePropertiesAsync((result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        const filePathIndex = result.value.url.lastIndexOf('/') + 1;
        const nameFromUrl = (decodeURIComponent(result.value.url.slice(filePathIndex)));
        const filenameIndex = nameFromUrl.lastIndexOf('\\') + 1;
        const name = nameFromUrl.slice(filenameIndex);
        const ext = name.split('.').pop();
        let tempFilename = '';
        if (ext) {
          setExtension(`.${ext}`);
          tempFilename = name;
        } else {
          tempFilename = `${name || ' '}${extension}`;
        }
        setFilename(tempFilename);
        setCurrentDocument({ ...currentDoc, filename: tempFilename });
        setInputKey(tempFilename);
      }
    });
  }

  useEffect(() => {
    setInputKey(currentDoc?.filename as string);
  }, [currentDoc]);

  useEffect(() => {
    if (currentFolder) { setFolderDestination(currentFolder.folderId.native); }
  }, [currentFolder]);

  const {
    addDocument,
  } = useCreateDoc();

  const {
    addDocumentToProject,
  } = useMutateDocAttachToProject();

  const {
    addDocumentRevision,
  } = useMutateDocSaveAsRevision();

  const handleSaveDoc = useCallback(() => {
    setErrorOnSave('');

    if (!currentProjectId || errors.hasErrors) return;

    if (filename.trim() === extension) {
      setErrors({ hasErrors: true, errorMessage: 'Filename cannot be missing.' });
      return;
    }

    setIsSavingDoc(true);

    Office.context.document.getFileAsync(Office.FileType.Compressed, {}, async (result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        const asyncFileResult = result.value;

        const getSlice = (i: number): Promise<Uint8Array> => new Promise((resolve) => {
          asyncFileResult.getSliceAsync(i, (sliceResult) => {
            if (sliceResult.status === Office.AsyncResultStatus.Succeeded) {
              resolve(new Uint8Array(sliceResult.value.data));
            }
          });
        });

        const slicePromises = [];

        for (let i = 0; i < asyncFileResult.sliceCount; i += 1) {
          slicePromises.push(getSlice(i));
        }

        try {
          const slices = await Promise.all(slicePromises);

          try {
            const addDocResponse = await addDocument({
              size: asyncFileResult.size,
              filename,
              folderId: { native: folderDestination },
              projectId: { native: currentProjectId },
            });

            const { documentId, contentType, url } = addDocResponse?.data?.docUploadURL;

            const blob = new Blob([...slices], {
              type: contentType,
            });

            const file = new File([blob], filename, { type: contentType });
            try {
              await fetch(url, { method: 'PUT', body: file });
              const isExistingDoc = currentDoc?.filename === filename
                && currentDoc?.folderId?.native === folderDestination;
              try {
                let savedDocument: IDocument;

                if (isExistingDoc) {
                  const claimDocAsRevisionResponse = await addDocumentRevision({
                    originalDocId: { native: currentDoc?.documentId?.native as number },
                    revisionDocId: documentId,
                  });
                  savedDocument = claimDocAsRevisionResponse?.data?.document as IDocument;
                } else {
                  const claimDocForProjectResponse = await addDocumentToProject({
                    folderId: { native: folderDestination || rootFolderId },
                    projectId: { native: currentProjectId },
                    documentId,
                  });
                  savedDocument = claimDocForProjectResponse?.data?.document as IDocument;
                }

                setCurrentDocument(savedDocument);
                setFilename(savedDocument?.filename);
                setInputKey(savedDocument?.filename);
                setIsSuccess(true);
              } catch (error) {
                setErrorOnSave(isExistingDoc ? 'Error saving new document version' : 'Error claiming document for project');
              }
            } catch (error) {
              setErrorOnSave('Error uploading data to AWS');
            }
          } catch (error) {
            setErrorOnSave('Error while creating document record');
          }
        } catch (error) {
          setErrorOnSave('Error while getting document data');
        } finally {
          asyncFileResult.closeAsync();
          setIsSavingDoc(false);
        }
      }
    });
  }, [
    addDocument,
    addDocumentRevision,
    addDocumentToProject,
    currentDoc,
    currentProjectId,
    errors.hasErrors,
    extension,
    filename,
    folderDestination,
    rootFolderId,
    setCurrentDocument,
    setFilename,
  ]);

  const onUpdateFilename = (newName: string) => {
    setErrorOnSave('');
    setFilename(newName);
  };

  const updateParentFolder = (pFolder: IFolder) => {
    setErrorOnSave('');
    if (pFolder && !parentFolders.some((folder: IFolder) => folder === pFolder)) {
      setParentFolders(parentFolders?.concat(pFolder));
    }
  };

  const setSelected = (folder: IFolder | undefined) => {
    setCurrentFolder(folder);
    setFolderDestination(folder?.folderId?.native || rootFolderId);
  };
  const clearSelected = () => {
    setCurrentFolder(undefined);
    setFolderDestination(rootFolderId);
  };

  const getParentFromKey = (key: number): IFolder => parentFolders?.filter(
    (p: IFolder) => p?.folderId.native === key)[0];

  useEffect(() => {
    if (errorOnSave
      || errors.hasErrors
      || currentDoc?.filename !== (filename || '').trim()
      || currentDoc?.folderId?.native !== folderDestination
    ) {
      setIsSuccess(false);
    } else if (currentDoc
        && currentDoc.filename === (filename || '').trim()
        && currentDoc.folderId.native === folderDestination
    ) {
      setIsSuccess(true);
    }
  }, [isSuccess, errorOnSave, errors.hasErrors, currentDoc, filename, folderDestination]);

  return (
    <>
      <FilenameInput
        key={inputKey}
        filename={filename}
        extension={extension}
        errors={errors}
        setErrors={setErrors}
        onUpdateFilename={onUpdateFilename}
      />
      <div className={css.folderDestination}>
        <div className={css.folderPickerContainer}>
          <div className={css.destinationLabel}>Project Folder</div>
          <GenericCard
            outlined={false}
            buttonIcon={<FilevineIcon className={css.editIcon} icon="chevron-right" />}
            child={(
              <FolderPicker
                currentlySelectedFolder={currentFolder}
                setSelectedCallback={setSelected}
                clearSelectedCallback={clearSelected}
                setParentFolder={updateParentFolder}
                getParentFolder={getParentFromKey}
              />
            )}
          />
        </div>
        <DestinationFolder
          currentlySelectedFolder={currentFolder}
          getParentFolder={getParentFromKey}
        />
      </div>
      <div className={css.save}>
        <div className={css.saveCentered}>
          <div className={css.saveButton}>
            <Button
              unelevated
              onClick={handleSaveDoc}
              disabled={errors.hasErrors || isSavingDoc}
              data-test="saveButton"
            >
              Save To Project
            </Button>
          </div>
          <div className={css.saveProgress}>
            <LinearProgress
              indeterminate={isSavingDoc}
              closed={!isSavingDoc}
            />
          </div>
        </div>
      </div>
      {!!errorOnSave
      && (
      <div className={css.errorMessage}>
        {errorOnSave}
      </div>
      )}
      {!errorOnSave && isSuccess && (
        <div className={css.success}>
          Document saved to Project.
        </div>
      )}
    </>
  );
};

export default SaveAs;
