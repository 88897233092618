import React, { FC, useState } from 'react';
import Button from '@fv-components/button';

import { setUserDomain } from '../../config';
import FilevineIcon from '../../Taskpane/FilevineIcon';
import EphemeralId from '../../EphemeralId';
import { isInsidePopup } from '../../util-helpers/common';
import FVID from '../FVID';

const css = require('./Login.module.scss');

const LoginFVID: FC = () => {
  const [domain, setDomain] = useState<string | null>(null);

  const handleDomainChange = (userDomain: string) => {
    setUserDomain(`.${userDomain}`);
    setDomain(userDomain);
    FVID.setAuthConfig();
  };

  return (
    <div className={css.container}>
      <div className={css.content} style={{ paddingTop: 50 }}>
        <EphemeralId />
        {!FVID.user?.profile || FVID.user?.expired
          ? (
            <div className={css.fvidLoginBox}>
              <img
                alt="Filevine"
                height="45"
                src="assets/fv-logo.svg"
                style={{ marginLeft: 50 }}
              />
              <p className={css.fvidLoginBody}>Select your Filevine domain location</p>
              <div className={css.locationOptionContainer}>
                <div className={`${css.locationOption} ${domain === "filevineapp.com" && css.active}`} onClick={() => handleDomainChange("filevineapp.com")}>
                  <p className={css.locationCountry}>United States</p>
                  <p className={css.locationDomain}>filevineapp.com</p>
                </div>
                <div className={`${css.locationOption} ${domain === "filevineapp.ca" && css.active}`} onClick={() => handleDomainChange("filevineapp.ca")}>
                  <p className={css.locationCountry}>Canada</p>
                  <p className={css.locationDomain}>filevineapp.ca</p>
                </div>
              </div>
              <Button
                className={css.customBtn}
                style={{ backgroundColor: domain === null ? "#E0E0E0" : "#009D9A", borderRadius: 0, width: "80%", marginTop: 20, color: "white" }}
                disabled={domain === null}
                onClick={() => {
                  FVID.authorize();
                }}>
                Continue to login
                {' '}
                <FilevineIcon icon="chevron-right" className={css.fvidButtonIconLeft} />
              </Button>
            </div>
          )
          : (
            <>
              {FVID.user?.profile && !FVID.user?.expired && <>
                <div className={css.fvidLoginBox}>
                  <img
                    alt="Filevine"
                    height="45"
                    src="assets/fv-logo.svg"
                    style={{ marginLeft: 50, marginBottom: 30 }}
                  />
                  <p className={css.fvidLoginBody}>
                    You are signed in as
                    <b>{FVID.user?.profile?.name}</b>
                  </p>
                  <Button
                    className={css.customBtn}
                    style={{ backgroundColor: "#009D9A", borderRadius: 0, width: "80%", marginTop: 0, marginBottom: 20, color: "white" }}
                    onClick={FVID.logout}>
                    <FilevineIcon icon="chevron-left" className={css.fvidButtonIconRight} />
                    {' '}
                    Sign Out
                  </Button>
                  {isInsidePopup() && <p className={css.fvidLoginBody}>You may close this poup</p>}
                  {!isInsidePopup() && <p className={css.fvidLoginBody}><a href="/">Open Filevine Taskpane</a></p>}
                </div>
              </>}
            </>
          )}
      </div>
    </div>
  );
};

export default LoginFVID;
