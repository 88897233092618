import React, { FC } from 'react';
import { handleMSALLogout } from '../util-helpers/msal';

const MsalLogout: FC = () => {
  handleMSALLogout();

  return <div> Microsoft authentication logout in progress.</div>;
};

export default MsalLogout;
