import { useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { ExecutionResult } from 'graphql';

export interface ICreateNoteData {
  noteId: { partner: string; };
  projectId: { native: number; };
  body: string;
  documents: { native: number; }[];
  authorId: {
    native: number;
  };
}

interface INoteResponse {
  noteId: {
    native: number;
  };
}

export interface INotePayload {
  addNote: (noteData: ICreateNoteData) => Promise<ExecutionResult>;
  loading: boolean;
  error?: ApolloError;
  called: boolean;
  noteResponse?: {
    noteId: {
      native: number;
    };
  };
}

export const ADD_NOTE = gql`
  mutation AddNote ( $noteData: ICreateNoteData! ) {
    noteResponse (noteData: $noteData )
      @rest(
        type: "Note",
        path: "/notes",
        method: "POST",
        bodyKey: "noteData"
      ) {
        noteId
    }
  }`;

const useCreateNoteForDoc = (): INotePayload => {
  const [executeQuery, {
    data, error, loading, called,
  }] = useMutation<
    { noteResponse: INoteResponse }, { noteData: ICreateNoteData; }
  >(
    ADD_NOTE, { notifyOnNetworkStatusChange: true },
  );

  const addNote = useCallback(
    (noteData:ICreateNoteData) => executeQuery(
      { variables: { noteData } },
    ), [executeQuery],
  );

  return {
    addNote,
    error,
    loading,
    called,
    noteResponse: data && { ...(data && data.noteResponse) },
  };
};

export default useCreateNoteForDoc;
