import { createContext } from 'react';
import { IFolder } from '../../../util-api/useQueryFolderList';

export interface ISaveAsContext {
  currentFolder?: IFolder | undefined;
  filename: string;
  extension: string;
  parentFolders: IFolder[];
  setCurrentFolder: React.Dispatch<React.SetStateAction<IFolder | undefined>>;
  setFilename: React.Dispatch<React.SetStateAction<string>>;
  setExtension: React.Dispatch<React.SetStateAction<string>>;
  setParentFolders: React.Dispatch<React.SetStateAction<IFolder[]>>;
}

const SaveAsContext = createContext<ISaveAsContext>({
  filename: '',
  extension: '',
  parentFolders: [],
  setCurrentFolder: () => ({}),
  setFilename: () => '',
  setExtension: () => '',
  setParentFolders: () => [],
});

export default SaveAsContext;
