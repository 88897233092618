import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import Button from '@fv-components/button';
import useQueryProjectTypes from '../../util-api/useQueryProjectTypes';
import useCreateProject from '../../util-api/useCreateProject';
import { PersonType } from '../../util-api/useCreateOrgContact';
import { IProject } from '../../util-api/models/project';
import { getIsMacWebkit } from '../../util-helpers/office';
import CreateContact from '../CreateContact';
import { IOrgPerson } from '../CreateContact/CreateContact';
import GenericCard from '../ProjectWorkspace/GenericCard';
import FilevineIcon from '../FilevineIcon';
import SearchOrgContacts from '../SearchOrgContacts/SearchOrgContacts';
import SnackbarContext from '../../Snackbar/SnackbarContext';
import ErrorMsg from '../../ErrorMsg';

const css = require('./CreateProjectForm.module.scss');

interface IProjectPayload {
  projectTypeId?: number;
  clientId?: number;
  projectName?: string;
}

interface IProjectType { id: number, name: string }

interface IProps {
  orgName: string;
  onProjectCreateSuccess: (project: IProject) => void;
}

const CreateProjectForm = ({
  orgName,
  onProjectCreateSuccess,
}: IProps) => {
  const { addMessage: addSnackbarMessage } = useContext(SnackbarContext);
  const [projectPayload, setProjectPayload] = useState<IProjectPayload>();
  const [clientName, setClientName] = useState<string>();
  const [closeContacts, setCloseContacts] = useState(false);
  const [projectTypes, setProjectTypes] = useState<IProjectType[]>();
  const { projectTypesResults, getProjectTypes } = useQueryProjectTypes();
  const { addProject } = useCreateProject();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (!projectTypesResults?.called) getProjectTypes();
  }, [getProjectTypes, projectTypesResults]);

  useEffect(() => {
    if (projectTypesResults.called
      && !projectTypesResults.loading
      && !projectTypesResults.error
      && projectTypesResults.items
      && !projectTypes) {
      setProjectTypes(projectTypesResults.items.map((p) => ({
        id: p.projectTypeId.native,
        name: p.name,
      })));
    }
  }, [projectTypes, projectTypesResults]);

  useEffect(() => {
    if (projectTypes && !projectPayload?.projectTypeId) {
      setProjectPayload((prev) => ({
        ...prev,
        projectTypeId: projectTypes[0].id,
      }));
    }
  }, [projectPayload, projectTypes]);

  const selectProjectType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const projectTypeId = +e.currentTarget.value;
    if (!projectTypeId) setIsDisabled(true);
    if (projectTypeId && projectPayload?.clientId) setIsDisabled(false);
    setProjectPayload((prev) => ({
      ...prev,
      projectTypeId,
    }));
  };

  const onSelectClient = (person: IOrgPerson) => {
    addSnackbarMessage(`Client Added: ${person.fullName}`);
    setClientName(person.fullName);
    if (!person.personId.native) setIsDisabled(true);
    if (person.personId.native && projectPayload?.projectTypeId) setIsDisabled(false);
    setProjectPayload((prev) => ({
      ...prev,
      clientId: person.personId.native,
    }));
  };

  const onClearClientId = () => {
    setClientName('');
    setIsDisabled(true);
    setProjectPayload((prev) => ({
      ...prev,
      clientId: undefined,
    }));
  };

  const handleFail = (arg: Error) => {
    setErrorMsg(`${arg}`);
    setIsDisabled(false);
  };

  const onCreateProject = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsDisabled(true);
    try {
      if (projectPayload?.projectTypeId && projectPayload.clientId) {
        const project = await addProject(
          {
            projectTypeId: {
              native: projectPayload.projectTypeId,
            },
            clientId: {
              native: projectPayload.clientId,
            },
            projectName: projectPayload?.projectName,
          },
        );
        if (project?.data?.project) await onProjectCreateSuccess(project.data.project);
      }
    } catch (error) {
      handleFail(new Error('There was an error creating this project. Please check your entries.'));
    }
  };

  return (
    <div className={css.createProjectForm}>
      <h3>Create a Project</h3>

      <input
        type="text"
        disabled
        value={orgName}
        className={`${css.orgName} mdc-menu-surface--anchor`}
      />

      {projectTypes && (
        <>
          <select
            className={getIsMacWebkit() ? `${css.projectTypesSelect} ${css.projectTypesSelectMac}` : css.projectTypesSelect}
            data-test="projectTypeSelect"
            onChange={selectProjectType}
          >
            {projectTypes.map(
              (pt) => (
                <option
                  key={pt.id}
                  value={pt.id}
                >
                  {pt.name}
                </option>
              ),
            )}
          </select>
          <div className={css.orgClientWrapper}>
            {projectPayload?.clientId && clientName && (
            <>
              <div className={css.personChickletWrapper}>
                <div className={css.personChicklet}>
                  <span className={css.personChickletLabel}>{clientName}</span>
                  <Button
                    className={css.projectClientRemove}
                    onClick={onClearClientId}
                    onKeyDown={onClearClientId}
                    title="Remove Client"
                  >
                    <FilevineIcon icon="times-circle-o" />
                  </Button>
                </div>
              </div>
              <div className={css.personFakeAddWrapper}>
                <FilevineIcon icon="user-plus" />
              </div>
            </>
          )}
            {!projectPayload?.clientId && !clientName && (
            <>
              <GenericCard
                outlined={false}
                buttonTitle="Search or Add Client"
                buttonText="Search or Add Client"
                onClose={() => {
                  if (closeContacts) setCloseContacts(false);
                }}
                close={closeContacts}
                genCardButtonClass={css.searchOrgContactsGenCard}
                dataTestUniq="searchOrgContacts"
                child={(
                  <SearchOrgContacts
                    onSelect={onSelectClient}
                  />
              )}
              />
              <GenericCard
                outlined={false}
                buttonIcon={<FilevineIcon icon="user-plus" />}
                buttonTitle="Create a Client"
                onClose={() => {
                  if (closeContacts) setCloseContacts(false);
                }}
                close={closeContacts}
                genCardButtonClass={css.createClientGenCard}
                dataTestUniq="createProjectClient"
                child={(
                  <CreateContact
                    formTitle="Create a Client"
                    cta="Create Client"
                    onSuccess={onSelectClient}
                    personType={PersonType.Client}
                  />
              )}
              />
            </>
            )}
          </div>
          <form
            onSubmit={onCreateProject}
          >
            <div className={css.projectNameWrapper}>
              <input
                className={`${css.projectName} mdc-menu-surface--anchor`}
                type="text"
                placeholder="Project Name (Optional)"
                title="Project Name (Optional)"
                defaultValue={projectPayload?.projectName}
                onChange={(e) => {
                  const projectName = e.currentTarget.value.trim();
                  setProjectPayload((prev) => ({
                    ...prev,
                    projectName,
                  }));
                }}
              />
            </div>
            <div className={css.projectSubmitWrapper}>
              <Button
                type="submit"
                unelevated
                className={css.projectSubmit}
                disabled={isDisabled}
              >
                Create Project
              </Button>
            </div>
          </form>
        </>
      )}
      {projectTypesResults.called && projectTypesResults.loading && (
        <div>Loading Project Types...</div>
      )}
      {projectTypesResults.called && projectTypesResults.error && (
        <div className={css.createContactErrorText}>
          <ErrorMsg errorText="Error Loading Project Types" contactSupport />
        </div>
      )}
      {errorMsg && (
        <div className={css.createContactErrorText}>
          <ErrorMsg errorText={errorMsg} contactSupport />
        </div>
      )}
    </div>
  );
};

export default CreateProjectForm;
