import React, {
  FC, useEffect, useState, useContext,
} from 'react';

// Utilities
import LinearProgress from '@fv-components/linear-progress';

// FV API
import useQueryNote from '../../../util-api/useQueryNote';
import useQueryProjectTeam from '../../../util-api/useQueryProjectTeam';
import useCreateNoteForDoc, { ICreateNoteData } from '../../../util-api/useCreateNoteForDoc';
import DocumentContext from '../DocumentContext';

// Child Components
import DocActivityInput from './DocActivityInput';
import DocActivityHeader from './DocActivityHeader';
import EmailActivityChat from '../../../Taskpane/ProjectWorkspace/EmailActivity/EmailActivityChat';
import SaveAs from '../SaveAs';

// Styles
const css = require('./DocActivity.module.scss');

interface IDocActivityProps {
  currentUserId: string;
  currentProjectId: number;
  scrollContainer: React.RefObject<HTMLDivElement>;
}

const NOTE_ERROR_TEXT = 'We had trouble adding this note to your Filevine project.';

const DocActivity: FC<IDocActivityProps> = (
  {
    currentUserId,
    currentProjectId,
    scrollContainer,
  }: IDocActivityProps,
) => {
  const { currentDocId } = useContext(DocumentContext);

  // Local State
  const [isInputFocus, setIsInputFocus] = useState(false);
  const [isRefreshingComments, setIsRefreshingComments] = useState(false);
  const [isCommentAdded, setIsCommentAdded] = useState(false);
  const [errorMsg, setErrormsg] = useState('');
  const [isSending, setIsSending] = useState(false);
  const partnerId = currentDocId ? `docId:${currentDocId}-projectId:${currentProjectId}` : '';

  // Custom Hooks
  const {
    addNote,
    loading: isCreatingNote,
  } = useCreateNoteForDoc();

  const {
    getProjectTeam,
    projectTeamSearchResults: { team },
  } = useQueryProjectTeam();

  const {
    getNote,
    note,
    noteLoading,
  } = useQueryNote();

  const noteId = note?.noteId?.native;

  const getTeamMemberName = (id: number) => {
    let author;
    if (team && team.items) {
      author = team.items.find((t) => t.userId.native === id);
    }
    return author ? author.fullname : '';
  };

  useEffect(() => {
    if (currentProjectId) {
      getProjectTeam(currentProjectId, '', 1000);
    }
  }, [currentProjectId, getProjectTeam]);

  useEffect(() => {
    const getNoteAsync = async () => {
      await getNote(`@${partnerId}`);
    };
    if (partnerId) { getNoteAsync(); }
  }, [partnerId, getNote]);

  const isDoneSending = (completed: boolean) => {
    if (isSending && completed) {
      setIsSending(false);
    }
  };

  const onCommentsAdded = () => {
    setIsCommentAdded(true);
  };

  const onCommentsRefreshed = () => {
    setIsRefreshingComments(false);
    setIsCommentAdded(false);
    getNote(`@${partnerId}`);
  };

  const onAddNote = async (comment: string) => {
    setIsSending(true);
    try {
      const noteData: ICreateNoteData = {
        body: comment || '',
        documents: [{ native: currentDocId as number }],
        projectId: { native: currentProjectId },
        noteId: { partner: partnerId },
        authorId: { native: +currentUserId },
      };

      const executionResult = await addNote(noteData);
      if (executionResult && !executionResult.errors) {
        getNote(`@${partnerId}`);
        setIsSending(false);
        setIsCommentAdded(true);
      } else {
        setErrormsg(NOTE_ERROR_TEXT);
        setIsSending(false);
        setIsCommentAdded(true);
      }
    } catch (error) {
      setErrormsg(`${error}` || NOTE_ERROR_TEXT);
      setIsSending(false);
    }
  };

  return (currentDocId ? (
    <div className={css.DocActivityContainer}>
      <DocActivityHeader
        getTeamMemberName={getTeamMemberName}
        note={note}
        isInputFocus={isInputFocus}
      />

      {!!noteId
        && (
          <EmailActivityChat
            getTeamMemberName={getTeamMemberName}
            noteId={noteId}
            isRefreshingComments={isRefreshingComments}
            isCommentAdded={isCommentAdded}
            onCommentsRefreshed={onCommentsRefreshed}
            scrollContainer={scrollContainer}
          />
        )}

      <div className={css.DocActivityInputContainer}>
        <div className={css.DocActivityDivider}>
          <LinearProgress
            indeterminate={noteLoading}
            closed={!noteLoading}
          />
        </div>

        <DocActivityInput
          projectId={currentProjectId}
          noteId={noteId}
          userId={currentUserId}
          onCommentAdded={onCommentsAdded}
          onAddNote={onAddNote}
          isSavingNote={isCreatingNote && isSending}
          doneSending={isDoneSending}
          refreshComments={() => setIsRefreshingComments(true)}
          onIsInputFocus={(focus: boolean) => setIsInputFocus(focus)}
          parentErrorMessage={errorMsg}
        />
      </div>
    </div>
  )
    : <SaveAs />
  );
};

export default DocActivity;
