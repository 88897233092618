import { useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { ExecutionResult } from 'graphql';
import { IDocument } from './models/document';

export interface IAttachDocDataReq {
  documentId: { native: number; };
  projectId: { native: number; };
  folderId: { native: number; };
}

export interface IAttachDocPayload {
  addDocumentToProject: (payload: IAttachDocDataReq) => Promise<ExecutionResult>;
  loading: boolean;
  error?: ApolloError;
  called: boolean;
  document?: IDocument;
}

export const ADD_DOCUMENT_TO_PROJECT = gql`
  mutation AddDocumentToProject ( $documentId: number!, $projectId: number!, $folderId: number! ) {
    document ( documentId: $documentId, projectId: $projectId, folderId: $folderId, input: {} )
      @rest(
        type: "Document",
        path: "/projects/{args.projectId}/documents/{args.documentId}?folderId={args.folderId}",
        method: "POST"
      ) {
        documentId
        filename
        folderName
        size
        folderId
        projectId
        uploaderId
        uploadDate
        hashtags
        uploaderFullname
      } 
  }`;

const useMutateAttachDocToProject = (): IAttachDocPayload => {
  const [executeQuery, {
    data,
    error,
    loading,
    called,
  }] = useMutation<
    { document: IDocument; }, { documentId: number; projectId: number; folderId: number; }
  >(
    ADD_DOCUMENT_TO_PROJECT, { notifyOnNetworkStatusChange: true },
  );

  const addDocumentToProject = useCallback(
    ({ documentId, projectId, folderId }: IAttachDocDataReq) => executeQuery(
      {
        variables: {
          documentId: documentId.native,
          projectId: projectId.native,
          folderId: folderId.native,
        },
      },
    ), [executeQuery],
  );

  return {
    addDocumentToProject,
    error,
    loading,
    called,
    document: (!error && data) ? data.document : undefined,
  };
};

export default useMutateAttachDocToProject;
