import React, {
  FC, useContext,
} from 'react';
import dayjs from 'dayjs';
import DocumentContext from '../../DocumentContext';
import { INote } from '../../../../util-api/useQueryNote';
import { dateFormat } from '../../../../util-helpers/common';
import { getIsMobile } from '../../../../util-helpers/office';

const css = require('./DocActivityHeader.module.scss');

const formatDueDate = (dueDateISO: string) => (
  dayjs(dueDateISO, { utc: true }).format(dateFormat.DOW_AND_DATE)
);
const labels = {
  overdue: 'Overdue',
  today: 'Due today',
  future: 'Set for',
};
const taskDueDateStatus = (dueDate: string): string => {
  const now = new Date();
  const today = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
  const isOverdue = Date.parse(dueDate) < today;
  const isDueToday = Date.parse(dueDate) === today;
  if (isOverdue) {
    return labels.overdue;
  }
  if (isDueToday) {
    return labels.today;
  }
  return labels.future;
};

interface IDocActivityHeaderProps {
  note?: INote;
  getTeamMemberName: (id: number) => string;
  isInputFocus: boolean;
}

const DocActivityHeader: FC<IDocActivityHeaderProps> = (
  {
    getTeamMemberName,
    note,
    isInputFocus,
  }: IDocActivityHeaderProps,
) => {
  const { currentDoc } = useContext(DocumentContext);
  const taskStatus = note?.targetDate && taskDueDateStatus(note?.targetDate);
  const taskDueDateStatusCSSClass = (taskStatusStr: string) => {
    switch (taskStatusStr) {
      case labels.today:
        return css.docActivityTaskLabelDueToday;
      case labels.overdue:
        return css.docActivityTaskLabelOverdue;
      case labels.future:
      default:
        return css.docActivityTaskLabel;
    }
  };
  const formattedDueDate = note?.targetDate && formatDueDate(note.targetDate);
  const hideOnFocus = isInputFocus && getIsMobile();
  return (
    <div className={hideOnFocus ? css.docActivityHeaderInputFocus : css.docActivityHeader}>
      <div className={css.docActivityFilename}>
        {currentDoc?.filename}
      </div>

      {/* Task assignment widget */}
      {note && (note.assigneeId || note.targetDate) && (
        <>
          <div className={css.docActivityTask}>
            {note.assigneeId && note.assigneeId.native && (
              <div className={css.docActivityTaskDetail}>
                <span className={css.docActivityTaskLabel}>Assigned to: </span>
                {getTeamMemberName(note.assigneeId.native)}
              </div>
            )}

            {/* Task Due in Future */}
            {note.targetDate && !note.isCompleted && taskStatus && (
              <div>
                <span className={taskDueDateStatusCSSClass(taskStatus)}>{`${taskStatus}:`}</span>
                &nbsp;
                {formattedDueDate}
              </div>
            )}

            {/* Completed Task */}
            {note.targetDate && note.isCompleted && note.completedDate && (
              <div>
                <span className={css.docActivityTaskLabel}>Completed on: </span>
                {formatDueDate(note.completedDate)}
              </div>
            )}

          </div>
          <div className={css.docActivityTaskDivider} />
        </>
      )}
    </div>
  );
};

export default DocActivityHeader;
