import { useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import gql from 'graphql-tag';
import { useCallback } from 'react';

interface IProjectTypes {
  projectTypeId: {
    native: number,
    partner: number,
  },
  name: string,
  links: {}
}

export interface IProjectTypesPayload {
  called: boolean;
  loading: boolean;
  error?: ApolloError;
  items: IProjectTypes[];
  limit?: number;
}
export interface IQueryProjectTypesHook {
  projectTypesResults: IProjectTypesPayload,
  getProjectTypes: () => void;
}

export const PROJECT_TYPES_QUERY = gql`
  query projectTypesQuery {
    projectTypes
      @rest(type: "ProjectTypes", path: "/projecttypes?limit=1000") {
        count
        items
      }
  }`;

const useQueryProjectTypes = (): IQueryProjectTypesHook => {
  const [executeQuery, {
    data, error, loading, called,
  }] = useLazyQuery<{projectTypes: IProjectTypesPayload}>(
    PROJECT_TYPES_QUERY, {
      fetchPolicy: 'no-cache',
    },
  );

  const getProjectTypes = useCallback(
    () => executeQuery(
      { variables: { } },
    ), [executeQuery],
  );

  return {
    projectTypesResults: {
      called,
      error,
      loading,
      items: [],
      limit: 1000,
      ...(!!data?.projectTypes?.items && {
        items: data.projectTypes.items,
      }),
    },
    getProjectTypes,
  };
};

export default useQueryProjectTypes;
