import { createContext } from 'react';

interface ISnackbarContext {
  addMessage: (text: string, isError?: boolean, isSticky?: boolean) => void;
}

const SnackbarContext = createContext<ISnackbarContext>({
  // eslint-disable-next-line no-unused-vars
  addMessage: (text: string, isError?: boolean, isSticky?: boolean) => {},
});

export default SnackbarContext;
