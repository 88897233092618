import gql from 'graphql-tag';
import { ExecutionResult } from 'graphql';
import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client/errors/ApolloError';

interface IFieldValues {
  fieldSelector: string;
  fieldName: string;
  value: number | string | boolean;
}
interface IBillingEntryData {
  projectId: number;
  isDraft: boolean;
  userId: string;
  description: string;
  time: string;
  timeCodes: {
    [key: string]: IFieldValues;
  }
}

interface IBillingEntryBody {
  projectId: number;
  isDraft: boolean;
  billingType: string;
  fieldValues: IFieldValues[];
}

interface IBillingEntryResponse {
  id: string;
}

export interface IBillingEntryPayload {
  addBillingEntry: (billingEntryData: IBillingEntryData) => Promise<ExecutionResult>;
  called: boolean;
  loading: boolean;
  error?: ApolloError;
  billingEntryRes?: IBillingEntryResponse;
}

export const formatBillingEntryBody = (data: IBillingEntryData): IBillingEntryBody => ({
  projectId: data.projectId,
  isDraft: data.isDraft,
  billingType: 'Time',
  fieldValues: [
    {
      fieldSelector: 'BillingItemUser',
      fieldName: 'User',
      value: data.userId,
    },
    {
      fieldSelector: 'BillingItemDescription',
      fieldName: 'Description',
      value: data.description,
    },
    {
      fieldSelector: 'BillingItemQuantity',
      fieldName: 'Quantity',
      value: data.time,
    },
    {
      fieldSelector: 'BillingItemDate',
      fieldName: 'Date',
      // Per billing team this should be submitted as the users Local TZ
      value: new Date().toLocaleString(),
    },
    {
      fieldSelector: 'BillingItemIsBillable',
      fieldName: 'Is Billable',
      value: true,
    },
    {
      fieldSelector: 'BillingItemIsChargeable',
      fieldName: 'Is Chargeable',
      value: true,
    },
    ...Object.keys(data.timeCodes).map((tc: string) => data.timeCodes[tc]),
  ],
});

export const ADD_BILLING_ENTRY = gql`
  mutation AddBillingEntry ( $billingEntryBody: IBillingEntryBody! ) {
    billingEntryRes ( billingEntryBody: $billingEntryBody )
      @rest(
        type: "BillingEntry",
        path: "/billing",
        method: "PUT",
        bodyKey: "billingEntryBody"
      ) {
        id
      }
  }`;

const useMutateBillingEntry = (): IBillingEntryPayload => {
  const [executeQuery, {
    data, error, loading, called,
  }] = useMutation<
    { billingEntryRes: IBillingEntryResponse }, {}
  >(
    ADD_BILLING_ENTRY, { notifyOnNetworkStatusChange: true },
  );

  const addBillingEntry = useCallback(
    (billingEntryData: IBillingEntryData) => {
      const billingEntryBody = formatBillingEntryBody(billingEntryData);

      return executeQuery(
        { variables: { billingEntryBody } },
      );
    }, [executeQuery],
  );

  return {
    addBillingEntry,
    called,
    loading,
    error,
    // If a 2nd call was made that errors we don't want to return the old data
    billingEntryRes: (!error && data) ? data.billingEntryRes : undefined,
  };
};

export default useMutateBillingEntry;
