import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { nrPageAction } from './util-helpers/newrelic';

let LSAccess;

try {
  // test for LS access
  localStorage.getItem('userTenant');
  LSAccess = true;
} catch (error) {
  LSAccess = false;
  nrPageAction('outlookLoad', { message: `LS Access: ${error}` });
}

if (LSAccess) {
  // must conditionally require the loader or the app crashes on LS access
  // eslint-disable-next-line
  const loadApp = require('./app-loader');
  loadApp.default();
} else {
  document.write(' There was an error with cookies or localStorage permissions. \n Please ensure they are enabled for this site and 3rd party-cookies.');
}
