import React, { FC } from 'react';
import Button from '@fv-components/button';

import {
  getIsReading, getIsMeeting,
} from '../../../util-helpers/office';
import { addRecipientToEmail } from '../../../util-helpers/composeMode';

interface IEmailActionButtonsProps {
  emailAddress: string;
  displayName: string;
}

const EmailActionButtons: FC<IEmailActionButtonsProps> = ({
  emailAddress, displayName,
}: IEmailActionButtonsProps) => {
  const isReading = getIsReading();
  const isMeeting = getIsMeeting();

  const meetingButtons = (
    <>
      <Button
        raised
        dense
        className="email-actions"
        onClick={() => addRecipientToEmail({ emailAddress, displayName }, 'requiredAttendees', isReading)}
      >
        Required
      </Button>
      <Button
        raised
        dense
        className="email-actions"
        onClick={() => addRecipientToEmail({ emailAddress, displayName }, 'optionalAttendees', isReading)}
      >
        Optional
      </Button>
    </>
  );

  const emailButtons = (
    <>
      <Button
        raised
        dense
        className="email-actions"
        onClick={() => addRecipientToEmail({ emailAddress, displayName }, 'to', isReading)}
      >
        To
      </Button>
      <Button
        raised
        dense
        className="email-actions"
        onClick={() => addRecipientToEmail({ emailAddress, displayName }, 'cc', isReading)}
      >
        CC
      </Button>
      <Button
        raised
        dense
        className="email-actions"
        onClick={() => addRecipientToEmail({ emailAddress, displayName }, 'bcc', isReading)}
      >
        BCC
      </Button>
    </>
  );

  // Actions can only be in composing
  if (!isReading) {
    if (!isMeeting) {
      return emailButtons;
    }
    return meetingButtons;
  }
  return <></>;
};

export default EmailActionButtons;
