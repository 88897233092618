import { useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { ExecutionResult } from 'graphql';

export enum PersonType {
  Adjuster = 'Adjuster',
  Attorney = 'Attorney',
  Client = 'Client',
  Court = 'Court',
  Defendant = 'Defendant',
  Plaintiff = 'Plaintiff',
  Expert = 'Expert',
  Firm = 'Firm',
  InsuranceCompany = 'Insurance Company',
  InvolvedParty = 'Involved Party',
  Judge = 'Judge',
  MedicalProvider = 'Medical Provider',
}

export enum CreateContactAction {
  Add = 'ADD',
  Update = 'UPDATE',
}

export enum CreateContactSelector {
  FirstName = 'firstName',
  LastName = 'lastName',
  Emails = 'emails',
  Phones = 'phones',
  PersonTypes = 'personTypes',
}

interface IEmailAddress {
  address: string;
}

interface IPhoneNumber {
  number: string;
}

export interface IContactDataReq {
  selector: CreateContactSelector;
  action: CreateContactAction;
  value: string | number | boolean | IEmailAddress | IPhoneNumber | PersonType;
}

export interface IContactDataRes {
  firstName: string;
  lastName?: string;
  fullName?: string;
  primaryEmail?: string;
  emails?: {
    address: string;
  }[];
  phones?: {
    number: string;
  }[];
  personId: {
    native: number;
  };
}

export interface IOrgContactCreateStateObj {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  personType?: number;
}

export interface IContactPayload {
  addContact: (payload: IOrgContactCreateStateObj) => Promise<ExecutionResult>;
  loading: boolean;
  error?: ApolloError;
  called: boolean;
  contact?: IContactDataRes;
}

export const ADD_CONTACT = gql`
  mutation AddContact ( $contactData: IContactDataReq! ) {
    contact ( contactData: $contactData )
      @rest(
        type: "Contact",
        path: "/custom-contacts",
        method: "POST",
        bodyKey: "contactData"
      ) {
        firstName,
        lastName,
        fullName,
        primaryEmail,
        emails,
        phones,
        personId,
      } 
  }`;

export const updateContactRequestPayload = (contact: IOrgContactCreateStateObj) => {
  const contactRequestPayload: IContactDataReq[] = [];
  if (contact?.firstName) {
    contactRequestPayload.push({
      selector: CreateContactSelector.FirstName,
      action: CreateContactAction.Update,
      value: contact.firstName,
    });
  }
  if (contact?.lastName) {
    contactRequestPayload.push({
      selector: CreateContactSelector.LastName,
      action: CreateContactAction.Update,
      value: contact.lastName,
    });
  }
  if (contact?.emailAddress) {
    contactRequestPayload.push({
      selector: CreateContactSelector.Emails,
      action: CreateContactAction.Add,
      value: { address: contact.emailAddress },
    });
  }
  if (contact?.phoneNumber) {
    contactRequestPayload.push({
      selector: CreateContactSelector.Phones,
      action: CreateContactAction.Add,
      value: { number: contact.phoneNumber },
    });
  }
  if (contact?.personType) {
    contactRequestPayload.push({
      selector: CreateContactSelector.PersonTypes,
      action: CreateContactAction.Add,
      value: contact.personType,
    });
  }
  return contactRequestPayload;
};

const useCreateOrgContact = (): IContactPayload => {
  const [executeQuery, {
    data,
    error,
    loading,
    called,
  }] = useMutation<
    { contact: IContactDataRes }, { contactData: IContactDataReq[] }
  >(
    ADD_CONTACT, { notifyOnNetworkStatusChange: true },
  );

  const addContact = useCallback(
    (contactData: IOrgContactCreateStateObj) => executeQuery(
      { variables: { contactData: updateContactRequestPayload(contactData) } },
    ), [executeQuery],
  );

  return {
    addContact,
    error,
    loading,
    called,
    contact: (!error && data) ? data.contact : undefined,
  };
};

export default useCreateOrgContact;
