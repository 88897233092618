import { useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { ExecutionResult } from 'graphql';

export interface IProjectContactDataReq {
  orgContactId: {
    native: number;
  };
}

export interface IProjectContactDataRes {
  projectId: {
    native: number;
  };
  projectContactId: {
    native: number;
  };
}

export interface IContactPayload {
  addProjectContact: (
    projectId: number,
    payload: IProjectContactDataReq,
  ) => Promise<ExecutionResult>;
  loading: boolean;
  error?: ApolloError;
  called: boolean;
  contact?: IProjectContactDataRes;
}

export const ADD_PROJECT_CONTACT = gql`
    mutation AddProjectContact ( $projectId: number, $contactData: IProjectContactDataReq! ) {
      contact ( id: $projectId, contactData: $contactData )
        @rest(
          type: "Contact",
          path: "/projects/{args.id}/contacts",
          method: "POST",
          bodyKey: "contactData"
        ) { projectId, projectContactId } 
    }`;

const useCreateProjectContact = (): IContactPayload => {
  const [executeQuery, {
    data,
    error,
    loading,
    called,
  }] = useMutation<
    { contact: IProjectContactDataRes }, { projectId: number; contactData: IProjectContactDataReq }
  >(
    ADD_PROJECT_CONTACT, { notifyOnNetworkStatusChange: true },
  );

  const addProjectContact = useCallback(
    (projectId: number, contactData: IProjectContactDataReq) => executeQuery(
      { variables: { projectId, contactData } },
    ), [executeQuery],
  );

  return {
    addProjectContact,
    error,
    loading,
    called,
    contact: (!error && data) ? data.contact : undefined,
  };
};

export default useCreateProjectContact;
