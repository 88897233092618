import { createContext } from 'react';
import { IDocument } from '../../util-api/models/document';

export interface IDocumentContext {
  currentDoc?: IDocument;
  currentDocId?: number;
  setCurrentDocument: React.Dispatch<React.SetStateAction<IDocument | undefined>>;
}

const DocumentContext = createContext<IDocumentContext>({
  setCurrentDocument: () => ({ filename: '' }),
});

export default DocumentContext;
