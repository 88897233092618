import React, {
  FC, useContext, useEffect, useState,
} from 'react';
import Button from '@fv-components/button';
import useQueryFolderList, { IFolder } from '../../../../util-api/useQueryFolderList';
import FilevineIcon from '../../../../Taskpane/FilevineIcon';
import ProjectContext from '../../../../Taskpane/ProjectContext';

const css = require('./FolderPicker.module.scss');

export interface IFolderPicker {
  currentlySelectedFolder: IFolder | undefined;
  setSelectedCallback: (folder: IFolder | undefined) => void;
  clearSelectedCallback: () => void;
  setParentFolder: (parentFolder: IFolder) => void;
  getParentFolder: (parentKey: number) => IFolder;
}

const FolderPicker: FC<IFolderPicker> = (
  {
    currentlySelectedFolder,
    setSelectedCallback,
    clearSelectedCallback,
    setParentFolder,
    getParentFolder,
  }: IFolderPicker,
) => {
  const { currentProjectId, currentProjectData } = useContext(ProjectContext);

  const [ascendingOrder, setAscendingOrder] = useState(true);

  const [isSortEnabled, setIsSortEnabled] = useState(false);

  const {
    getFolderList,
    folderListResult: {
      folderList,
      loading: foldersLoading,
    },
  } = useQueryFolderList();

  useEffect(() => {
    setIsSortEnabled((folderList?.items?.length ?? 0) > 1);
  }, [folderList]);

  useEffect(() => {
    getFolderList(currentlySelectedFolder?.links.children, currentProjectId, true, ascendingOrder);
  }, [currentProjectId, getFolderList, currentlySelectedFolder, ascendingOrder]);

  const handleChildFolderClicked = (folder: IFolder) => {
    setParentFolder(currentlySelectedFolder!);
    setSelectedCallback(folder);
  };

  const handleProjectClick = () => {
    clearSelectedCallback();
  };

  const handleParentFolderClicked = () => {
    setSelectedCallback(getParentFolder(currentlySelectedFolder?.parentId?.native || 0));
  };

  const getParentName = () => getParentFolder(
    currentlySelectedFolder?.parentId?.native || 0,
  ).name;

  const handleSortFolderList = () => {
    setAscendingOrder(!ascendingOrder);
  };

  return (
    <div className={css.folderPickerWrapper}>
      {/* FOLDER BREADCRUMB SECTION */}
      {currentlySelectedFolder
        ? (
          <div className={css.folderBreadcrumbSectionHighlighted}>
            <div className={css.breadCrumbs}>
              {/* Project Root */}
              <span
                role="button"
                tabIndex={0}
                onClick={handleProjectClick}
                onKeyDown={handleProjectClick}
                className={`${css.projectNameHighlighted} ${css.folderLink}`}
                data-test="projectName"
              >
                {currentProjectData
                  && currentProjectData?.projectOrClientName?.length > 10
                  ? `${currentProjectData?.projectOrClientName.slice(0, 10).trimEnd()}...`
                  : currentProjectData?.projectOrClientName}
                <FilevineIcon icon="chevron-right" className={css.icon} />
              </span>
              {/* Parent folder */}
              {currentlySelectedFolder.parentId?.native
                !== currentProjectData?.rootDocFolderId?.native
                && (
                  <>
                    <span
                      role="button"
                      tabIndex={0}
                      onClick={handleParentFolderClicked}
                      onKeyDown={handleParentFolderClicked}
                      className={`${css.parentNameHighlighted} ${css.folderLink}`}
                    >
                      {getParentName().length > 10 ? `${getParentName().slice(0, 10).trimEnd()}...` : getParentName() || 'Parent'}
                      <FilevineIcon icon="chevron-right" className={css.icon} />
                    </span>
                  </>
                )}
              {/* Current Folder */}
              {currentlySelectedFolder.name}
            </div>
          </div>
        )
        : (
          <div className={css.folderBreadcrumbSection} data-test="projectName">
            {currentProjectData?.projectOrClientName}
          </div>
        )}
      {/* SHOW CURRENTLY SELECTED FOLDER AND DIRECTORY */}
      <div className={css.docContainer}>
        {currentlySelectedFolder && (
          <div className={css.folderPlaceholder} data-test="folderPlaceholder">
            <FilevineIcon icon="folder" className={css.icon} />
            {currentlySelectedFolder?.name}
          </div>
        )}
        <Button
          className={css.sortButton}
          data-test="sortButton"
          onClick={handleSortFolderList}
          title="Sort"
          disabled={foldersLoading || !isSortEnabled}
        >
          <FilevineIcon icon={ascendingOrder ? 'sort-alpha-asc' : 'sort-alpha-desc'} />
          &nbsp;Sort
        </Button>
      </div>

      {/* FOLDER LIST */}
      <div className={css.container}>
        {folderList?.items?.map((folder: IFolder) => (
          <div className={css.folderContainer} key={folder.folderId.native}>
            <div
              key={`${folder.folderId.native}`}
              className={css.folderItem}
              role="button"
              tabIndex={0}
              data-test="folderItem"
              title={folder.name.length > 26 ? folder.name : ''}
              onClick={() => handleChildFolderClicked(folder)}
              onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>) => {
                if (e.key === 'Enter') {
                  handleChildFolderClicked(folder);
                }
              }}
            >
              <span>
                <FilevineIcon icon="folder" className={css.folderIcon} />
                {folder.name}
              </span>
            </div>
          </div>
        ))}

        {/* If loading */}
        {foldersLoading && <div>Loading... </div>}

        {/* IF NO SUBFOLDERS */}
        {!foldersLoading && (!folderList || folderList.count === 0) && <div>No sub folders</div>}
      </div>
    </div>
  );
};

export default FolderPicker;
