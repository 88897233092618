import { useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { ExecutionResult } from 'graphql';
import { IDocument } from './models/document';

export interface IDocRevisionReq {
  originalDocId: { native: number; };
  revisionDocId: { native: number; };
}

export interface IRevisionIdentifier {
  native: number;
  partner?: string;
}

export interface IAddDocRevisionPayload {
  addDocumentRevision: (payload: IDocRevisionReq) => Promise<ExecutionResult>;
  loading: boolean;
  error?: ApolloError;
  called: boolean;
  document?: IDocument;
}

export const ADD_DOCUMENT_REVISION = gql`
  mutation AddDocumentRevision ( $originalDocId: number!, $identifier: IRevisionIdentifier ) {
    document ( originalDocId: $originalDocId, identifier: $identifier )
      @rest(
        type: "Document",
        path: "/documents/{args.originalDocId}/revisions",
        method: "POST",
        bodyKey: "identifier"
      ) {
        documentId
        filename
        folderName
        size
        folderId
        projectId
        uploaderId
        uploadDate
        hashtags
        uploaderFullname
      } 
  }`;

const useMutateDocSaveAsRevision = (): IAddDocRevisionPayload => {
  const [executeQuery, {
    data,
    error,
    loading,
    called,
  }] = useMutation<
    { document: IDocument; }, { originalDocId: number; identifier: IRevisionIdentifier; }
  >(
    ADD_DOCUMENT_REVISION, { notifyOnNetworkStatusChange: true },
  );

  const addDocumentRevision = useCallback(
    ({ originalDocId, revisionDocId }: IDocRevisionReq) => executeQuery(
      {
        variables: {
          originalDocId: originalDocId.native,
          identifier: revisionDocId,
        },
      },
    ), [executeQuery],
  );

  return {
    addDocumentRevision,
    error,
    loading,
    called,
    document: (!error && data) ? data.document : undefined,
  };
};

export default useMutateDocSaveAsRevision;
