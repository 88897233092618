import React, {
  FC, useState, useRef,
} from 'react';
import LookupMenu from '../../LookupMenu';
import {
  IUserOrg,
  getUserOrgs,
  setOrgId,
  getCurrentOrg,
} from '../../Auth/auth';
import FilevineIcon from '../FilevineIcon';
import { getIsOutlook } from '../../util-helpers/office';

const css = require('./InlineOrgPicker.module.scss');

interface IInlineOrgPickerProps {
  onOrgSelected: (orgId: number) => void;
}

const InlineOrgPicker: FC<IInlineOrgPickerProps> = (
  {
    onOrgSelected,
  }: IInlineOrgPickerProps,
) => {
  const [orgPickerIsVisible, setOrgPickerIsVisible] = useState(false);
  const ref = useRef<HTMLAnchorElement>(null);

  const org = getCurrentOrg();
  const orgs = getUserOrgs();

  const onSelect = (item: IUserOrg) => {
    setOrgId(item.orgId);
    onOrgSelected(item.orgId);
  };

  const toggleMenu = (isOpen: boolean | null = null) => {
    setOrgPickerIsVisible((previous: boolean) => (isOpen !== null ? isOpen : !previous));
  };

  return (
    <>
      {orgs.length > 1 && (
        <>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            className={css.link}
            onClick={() => toggleMenu()}
            role="button"
            aria-label="Selected Org"
            ref={ref}
          >
            <span className={css.linkText}>{org?.name}</span>
            <span className={!getIsOutlook() ? css.pr15 : ''}>
              <FilevineIcon
                icon={orgPickerIsVisible ? 'chevron-down' : 'chevron-left'}
              />
            </span>
          </a>

          <LookupMenu<IUserOrg>
            inputRef={ref}
            descriptionField="name"
            keyField="orgId"
            onMenuItemSelected={onSelect}
            items={orgs}
            selectedOrgId={org?.orgId}
            isOpen={orgPickerIsVisible}
            onMenuClosed={() => toggleMenu(false)}
          />
        </>
      )}
    </>
  );
};

export default InlineOrgPicker;
