import { useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import gql from 'graphql-tag';
import { useCallback } from 'react';

export interface IContactMetaAllowedValues {
  name: string;
  value: number ;
}
export interface IContactsMeta {
  fieldName: string;
  selector: string;
  action: string;
  value: string;
  allowedValues: IContactMetaAllowedValues[];
  defaultValue: boolean;
  tab: { id: string; title: string; };
  fieldSet: { id: string; title: string };
  limitByPersonType: number[];
  examples: { selector: string; action: string; value: string; }[];
}

export interface IContactsMetaPayload {
  called: boolean;
  loading: boolean;
  error?: ApolloError;
  items?: IContactsMeta[];
}
export interface IQueryContactsMetaHook {
  contactsMetaResults: IContactsMetaPayload;
  getContactsMeta: () => void;
}

export const CONTACTS_META_QUERY = gql`
  query contactsMetaQuery {
    items
      @rest(type: "ContactsMeta", path: "/custom-contacts-meta") {
        fieldName,
        selector,
        action,
        value,
        allowedValues,
        defaultValue,
        tab,
        fieldSet,
        limitByPersonType,
        examples,
      }
  }`;

const useQueryContactsMeta = (): IQueryContactsMetaHook => {
  const [executeQuery, {
    data, error, loading, called,
  }] = useLazyQuery<IContactsMeta[]>(
    CONTACTS_META_QUERY, { fetchPolicy: 'no-cache' },
  );

  const getContactsMeta = useCallback(
    () => executeQuery(
      { variables: { } },
    ), [executeQuery],
  );

  return {
    contactsMetaResults: {
      called,
      error,
      loading,
      ...data,
    },
    getContactsMeta,
  };
};

export default useQueryContactsMeta;
