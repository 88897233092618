import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Button from '@fv-components/button';

import { getUserTenant } from './config';

// Utilities
import {
  configHasError,
} from './util-helpers/common';
import {
  getCurrentEmailId, getIsOfficeJs, getIsOutlook, getIsWord, getIsExcel, getIsPowerPoint,
} from './util-helpers/office';
import { pendoInitialize } from './util-helpers/pendo';
import { refreshMSALToken } from './util-helpers/msal';
import { nrPageAction } from './util-helpers/newrelic';

// Child Components
import {
  updateUserFromSession,
  validateUserAuthentication,
  logout,
  handleAmplifyConfigure,
  setSAMLAuth,
} from './Auth/auth';
import Landing from './Auth/Landing';
import { Callback, LoginCognito, LoginFVID } from './Auth/Login';
import Success from './Auth/Success';
import FullPageError from './FullPageError';
import Taskpane from './Taskpane';
import MsalAuth from './Msal/MsalAuth';
import MsalLogout from './Msal/MsalLogout';
import MsalLogoutSuccess from './Msal/MsalLogoutSuccess';
import MsalLanding from './Msal/MsalLanding';
import Progress from './Progress';
import Snackbar from './Snackbar';
import OfficeTaskpane from './TaskpaneOffice';
import ErrorBoundary from './ErrorBoundary';
import LogoutFVID from './Auth/Login/LogoutFVID';
import FVID from './Auth/FVID';

const loadApp = () => {
  try {
    const previousSetUseFVID = FVID.getUseFVID();
    let shouldUseFVID = previousSetUseFVID;
    if (!previousSetUseFVID) {
      shouldUseFVID = FVID.enableFVIDAuth();
    }

    let isAppInitialized = false;
    let isOutlook = false;
    let isWord = false;
    let isExcel = false;
    let isPowerPoint = false;

    let hasConfigError: boolean | undefined;
    let userLoginError: string | undefined;
    let validatedFVUser = false;
    let userUpdated = false;

    const render = async () => {
      let currentEmailId;
      try {
        currentEmailId = await getCurrentEmailId();
      } catch (error) {
        nrPageAction('outlookLoad', { message: `currentEmailId Error: ${error}` });
      }

      // users can get in a bad state, end up on the landing page inside the dialog & then are stuck
      // messageParent only exists on the child / dialog. this will close it in this case
      if (global.Office && Office?.context?.ui?.messageParent && (window.location.pathname === '/landing')) {
        nrPageAction('outlookLoad', { message: 'User in bad auth state, closing dialog' });
        Office.context.ui.messageParent('');
      }

      const isMSALPopupRoute = (
        window.location.pathname === '/msalauth'
        || window.location.pathname === '/msalrefresh'
        || window.location.pathname === '/msallogout'
        || window.location.pathname === '/msallogoutsuccess'
      );

      const isFVAuthRoute = (
        window.location.pathname === '/login'
        || window.location.pathname === '/success'
      );

      // If the user has a tenant configured update the user from FV
      if (getUserTenant() && !userUpdated && !isMSALPopupRoute) {
        // Validate the user config
        hasConfigError = configHasError();
        if (hasConfigError) {
          // eslint-disable-next-line no-console
          console.error('outlookLoad error Config Error');
          nrPageAction('outlookLoad', { message: `Config Error: ${hasConfigError}` });
          return;
        }

        // amplify should only be configured with SAML props if on the success page
        // reset here if the user is in a partial state
        if (!hasConfigError && window.location.pathname !== '/success') {
          setSAMLAuth('');
        }

        // Validate cognito and get user info from FV
        if (!hasConfigError && window.location.pathname !== '/login') {
          try {
            await handleAmplifyConfigure();
            validatedFVUser = await validateUserAuthentication();
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error('outlookLoad error Amplify Error');
            nrPageAction('outlookLoad', { message: `Amplify Error: ${error}` });
            userLoginError = 'Auth Config Error';
          }

          // Once the user is logged in (validatedFVUser)
          // We want to check this more than just at login but not every render (userUpdated)
          if (validatedFVUser && !userUpdated && !isFVAuthRoute) {
            try {
              await updateUserFromSession();
              userUpdated = true;
            } catch (error) {
              // eslint-disable-next-line no-console
              console.error('outlookLoad error userLogin Error');
              nrPageAction('outlookLoad', { message: `userLogin Error: ${error}` });
              userLoginError = (error as { message: string }).message;
            }

            if (!userLoginError) {
              try {
                await pendoInitialize();
              } catch (error) {
                // eslint-disable-next-line no-console
                console.error('outlookLoad error Pendo Error');
                nrPageAction('outlookLoad', { message: `Pendo Error: ${error}` });
                // we dont really care if pendo fails, keep moving
              }
            }
          }
        }
      }

      // MSAL Token check and refresh. False redirects user to MSALLanding.
      // Validate the token on non MSAL auth pages and if false they will be asked to login
      let validatedMSAL;
      if (
        validatedFVUser
        && window.location.pathname !== '/auth'
        && !isMSALPopupRoute
        && !isFVAuthRoute
        && !hasConfigError
      ) {
        validatedMSAL = await refreshMSALToken();
      }

      ReactDOM.render(
        <ErrorBoundary>
          <Snackbar normalTimeout={5000} errorTimeout={10000}>
            <Router>
              <Switch>
                {hasConfigError && (
                  <Route>
                    <FullPageError errorMessage="There was an error with configurations." />
                  </Route>
                )}
                {userLoginError && (
                  <Route>
                    <FullPageError errorMessage={userLoginError} />
                    <Button
                      onClick={logout}>
                      Sign Out
                    </Button>
                  </Route>
                )}

                {/* FV Auth routes */}
                <Route path="/login">{shouldUseFVID ? <LoginFVID /> : <LoginCognito />}</Route>
                <Route path="/logout"><LogoutFVID /></Route>
                <Route path="/signout"><Landing /></Route>
                <Route path="/success"><Success /></Route>
                <Route path="/callback"><Callback /></Route>

                {/* MSAL routes */}
                <Route path="/msallogoutsuccess"><MsalLogoutSuccess /></Route>
                <Route path="/msallogout"><MsalLogout /></Route>
                <Route path="/msalauth"><MsalAuth /></Route>
                <Route path="/auth"><MsalAuth /></Route>
                <Route path="/msalrefresh"><MsalLanding /></Route>

                {/* Show loading before Landing and Taskpane if office js is not ready */}
                {!isAppInitialized && (
                  <Route>
                    <Progress logo="assets/fv-logo.png" message="Office.js is Loading..." errorMsg="Loading Office.js Failed" />
                  </Route>
                )}
                <Route>
                  {shouldUseFVID && localStorage.getItem("user") !== null ? (
                    <>
                      {isOutlook && <Taskpane currentEmailId={currentEmailId} />}
                      {!isOutlook && (isWord || isExcel || isPowerPoint) && <OfficeTaskpane />}
                    </>
                  ) : (
                    <>
                      {!validatedFVUser && <Landing />}
                      {validatedFVUser && !validatedMSAL && <MsalLanding />}
                      {validatedFVUser && validatedMSAL && (
                        <>
                          {isOutlook && <Taskpane currentEmailId={currentEmailId} />}
                          {(isWord || isExcel || isPowerPoint) && <OfficeTaskpane />}
                        </>
                      )}
                    </>
                  )}
                </Route>
              </Switch>
            </Router>
          </Snackbar>
        </ErrorBoundary>,
        document.getElementById('container'),
      );
    };

    const loadOffice = (count = 0) => {
      const timeout = 5000;
      // Render office is loading if it is past the initial load
      if (count === 1) render();
      if (count <= 5) {
        if (global.Office) {
          (async () => {
            try {
              await Office.onReady();

              if (getIsOfficeJs()) {
                isOutlook = getIsOutlook();
                isWord = getIsWord();
                isExcel = getIsExcel();
                isPowerPoint = getIsPowerPoint();
              }

              isAppInitialized = true;
              render();
            } catch (error) {
              // eslint-disable-next-line no-console
              console.error(`Office onReady Error: ${error}`);
              nrPageAction('outlookLoad', { message: `Office onReady Error: ${error}` });
              setTimeout(() => {
                loadOffice(count + 1);
              }, timeout);
            }
          })();
        } else {
          // eslint-disable-next-line no-console
          console.error('No `Office` global found.');
          nrPageAction('outlookLoad', { message: 'No Office global' });
          setTimeout(() => {
            loadOffice(count + 1);
          }, timeout);
        }
      }
    };

    loadOffice();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error: ', error);
    nrPageAction('outlookLoad', { message: `General load ${error}` });
  }
};

export default loadApp;
