import { IComposeAttachment } from '../Taskpane/ProjectWorkspace/EmailActivity/EmailActivityAttachment/EmailActivityAttachment';
import {
  IMSOAttachment,
  IOutlookLocalItemPayload,
  IOutlookGraphAttachmentContent,
  IOutlookGraphItem,
  IOutlookGraphItemEmailAddress,
} from '../util-api/models/outlookItem';
import { IAttachment, IEmailContact, IEmailData } from '../util-api/useMutateEmail';
import graphFetch, { attachmentUpload } from '../util-api/msGraph';

import { simplifyHtml } from './common';
import { getAsyncEmailBody } from './office';
import { logMSApiEvent } from './msal';

// Format Outlook message attachments for upload to Filevine Project docs
// Outlook files have a base64 string as content
// Outlook eml and icalendar/ics files return content as a string representation of the file
// these need to be converted to base64 to be uploaded to FV appropriately
// Outlook url attachments return the url string as the content these should not be
// uploaded to FV as a base64 file
const formatAttachmentsForFV = (
  ac: IMSOAttachment,
  name: string,
): IAttachment => {
  switch (ac.attachment.format) {
    case Office.MailboxEnums.AttachmentContentFormat.Base64:
      return {
        name,
        content: ac.attachment.content,
        isBase64: true,
        folderId: ac.folderId,
      };
    case Office.MailboxEnums.AttachmentContentFormat.Eml:
      return {
        name: `${name}.eml`,
        content: ac.attachment.content,
        isBase64: false,
        folderId: ac.folderId,
      };
    case Office.MailboxEnums.AttachmentContentFormat.ICalendar:
      return {
        name: `${name}.ics`,
        content: ac.attachment.content,
        isBase64: false,
        folderId: ac.folderId,
      };
    case Office.MailboxEnums.AttachmentContentFormat.Url:
    default:
      return {
        name,
        content: ac.attachment.content,
        isBase64: false,
        folderId: ac.folderId,
      };
  }
};

const apiAddressToJsAddressGraph = (contact: IOutlookGraphItemEmailAddress): IEmailContact => {
  if (contact?.emailAddress) {
    return {
      address: contact.emailAddress.address,
      name: contact.emailAddress.address === contact.emailAddress.name ? '' : contact.emailAddress.name,
    };
  }
  return { name: '', address: '' };
};

const convertGraphPayload = async (message: IOutlookGraphItem): Promise<IEmailData> => ({
  from: {
    address: Office.context.mailbox.userProfile.emailAddress,
    name: Office.context.mailbox.userProfile.displayName,
  },
  to: message.toRecipients.map(apiAddressToJsAddressGraph),
  cc: message.ccRecipients && message.ccRecipients.map(apiAddressToJsAddressGraph),
  subject: message.subject,
  html: message.body && await simplifyHtml(message.body.content),
  headers: {
    date: message?.sentDateTime
      ? new Date(message?.sentDateTime).toString()
      : Date.now().toString(),
  },
  attachments: [],
  emailId: { partner: null },
});

const convertOutlookMessageToFVEmailNote = async (
  message: IOutlookGraphItem,
  selectedAttachments?: IComposeAttachment[],
  localAttachments?: IMSOAttachment[],
): Promise<IEmailData> => {
  const payload = await convertGraphPayload(message as IOutlookGraphItem);

  if (localAttachments?.length && selectedAttachments?.length) {
    selectedAttachments.forEach((selectedAttachment: IComposeAttachment) => {
      const saId = selectedAttachment.attachment.id;
      localAttachments.forEach((localAttachment: IMSOAttachment) => {
        const maId = localAttachment.attachment.id;
        if (saId === maId) {
          payload.attachments.push(formatAttachmentsForFV(
            {
              ...localAttachment,
              folderId: selectedAttachments
                .find((a) => a.attachment.id === localAttachment?.attachment.id)?.folderId || 0,
            },
            selectedAttachment.attachment.name,
          ));
        }
      });
    });
  }

  return payload;
};

// Call getMessage recursively with increasing delay if it returns 404
const getMessageRecursive = async (
  id: string,
  count: number,
): Promise<IOutlookGraphItem> => new Promise<IOutlookGraphItem>((resolve, reject) => {
  const asyncExecutor = async () => {
    try {
      const messageData = await graphFetch(`/me/messages/${id}`, 'GET');
      resolve(messageData);
    } catch (error) {
      // no message data, try again, increment count
      if (count <= 7) { // max wait at 7 tries is 84 secs
        setTimeout(() => resolve(getMessageRecursive(id, count + 1)), (3000 * count));
      } else {
        // retries exhausted, resolve with data
        reject(error);
      }
    }
  };
  asyncExecutor();
});

// recursively call the Outlook API to POST a new attachment to a draft message
const addedAttachments = async (
  endpoint: string,
  attachments: IMSOAttachment[],
): Promise<string> => new Promise((resolve, reject) => {
  const add = async (index: number) => {
    const { attachment } = attachments[index];
    if (attachment) {
      try {
        await attachmentUpload(endpoint, attachment);
        if (attachments[index + 1]) {
          await add(index + 1);
          return;
        }
        resolve(`Done adding at: ${index}`);
      } catch (error) {
        reject(error);
      }
    }
    resolve(`No more attachments to add at: ${index}`);
  };

  add(0);
});

// recursively call the Outlook API to DELETE a removed attachment from a draft message
const droppedAttachments = (
  endpoint: string,
  dA: IOutlookGraphAttachmentContent[],
): Promise<string> => new Promise((resolve, reject) => {
  const drop = async (index: number) => {
    const attachment = dA[index];
    if (attachment) {
      try {
        const deleted = await graphFetch(`/me/messages/${endpoint}/attachments/${(attachment as IOutlookGraphAttachmentContent).id}`, 'DELETE');
        if (index < dA.length) {
          await drop(index + 1);
          return;
        }
        resolve(`Done removing at: ${deleted}`);
      } catch (error) {
        reject(error);
      }
    }
    resolve(`No more attachments to remove at: ${index}`);
  };

  drop(0);
});

const getCurrentToRecipients = (
  item: Office.MessageCompose,
): Promise<Office.EmailAddressDetails[]> => new Promise((resolve, reject) => {
  item.to.getAsync(
    (asyncResult: Office.AsyncResult<Office.EmailAddressDetails[]>) => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        if (asyncResult.value.length > 0) {
          resolve(asyncResult.value);
        } else {
          reject(new Error('You need to add at least one recipient.'));
        }
      } else {
        const err = asyncResult.error;
        reject(new Error(`${err.name}: ${err.message}`));
      }
    },
  );
});

// saveAsync returns an itemId however in cache mode on desktop the item
// might not have propogated to the server yet, so in sendOutlookItem
// we check for the existence on the server and manually sync w/ server
const saveOutlookMessage = (
  item: Office.MessageCompose,
): Promise<string> => new Promise((resolve, reject) => {
  item.saveAsync(
    (asyncResult: Office.AsyncResult<string>) => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        const itemId = asyncResult.value;
        // No need to account for mobile's lack of convertToRestId method
        // as Outlook mobile does not support add-ins in compose mode
        const restId = Office.context.mailbox.convertToRestId(
          itemId,
          Office.MailboxEnums.RestVersion.v2_0,
        );
        resolve(restId);
      } else {
        const err = asyncResult.error;
        reject(new Error(`${err.name}: ${err.message}`));
      }
    },
  );
});

// Send the Draft from Outlook Web App
const prepDraftWebApp = async (
  localItemPayload: IOutlookLocalItemPayload,
  endpoint: string,
) => {
  try {
    const updateLocalMessageBody = JSON.stringify({
      From: localItemPayload.from,
    });
    const updateMessageData = await graphFetch(`/me/messages/${endpoint}`, 'PATCH', updateLocalMessageBody);
    return updateMessageData;
  } catch (error) {
    throw new Error(`Error Composing the Message: ${error}`);
  }
};

const getAsyncEmailCategories = async (
  categories: Office.Categories,
): Promise<Office.CategoryDetails[]> => (
  new Promise<Office.CategoryDetails[]>((resolve, reject) => {
    categories.getAsync(
      (asyncResult: Office.AsyncResult<Office.CategoryDetails[]>) => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          resolve(asyncResult.value || []);
        } else {
          reject(new Error(`There was an error getting the email categories: ${asyncResult.error.message}`));
        }
      },
    );
  })
);

// Send the Draft from Outlook Web App
const prepDraftDesktopApp = async (
  localItemPayload: IOutlookLocalItemPayload,
  endpoint: string,
  // eslint-disable-next-line consistent-return
) => {
  try {
    const updateLocalMessageBody = {
      ToRecipients: localItemPayload.toRecipients,
      CcRecipients: localItemPayload.ccRecipients,
      BccRecipients: localItemPayload.bccRecipients,
      From: localItemPayload.from,
      Body: localItemPayload.body,
      Subject: localItemPayload.subject,
      Categories: await getAsyncEmailCategories(Office.context.mailbox.item.categories),
    };
    const localMessageBody = JSON.stringify(updateLocalMessageBody);

    // Ensure the desktop message has been synced before trying to update etc
    const serverMessage = await getMessageRecursive(endpoint, 0);
    if (!serverMessage) {
      logMSApiEvent('getMessageRecursive exhausted retries', 'MSGraphCall');
      throw new Error('Error desktop message has not been synced to the server. Try sending again or saving as draft before sending.');
    }

    const updateMessageData = await graphFetch(`/me/messages/${endpoint}`, 'PATCH', localMessageBody);

    if (
      localItemPayload.attachments?.length
      || (updateMessageData.hasAttachments && !localItemPayload.attachments?.length)
    ) {
      const { value: serverAttachments } = await graphFetch(`/me/messages/${endpoint}/attachments`, 'GET');
      const localAttachments = localItemPayload.attachments || [];

      // REMOVE ATTACHMENTS FROM SERVER
      const dropFilterCallback = (
        attachmentFile: IOutlookGraphAttachmentContent,
      ) => !localAttachments.some(
        ({ attachment: { name } }: IMSOAttachment) => (
          name === (attachmentFile as IOutlookGraphAttachmentContent).name
        ),
      );
      const dropAttachments: IOutlookGraphAttachmentContent[] = serverAttachments.filter(
        dropFilterCallback,
      );

      if (dropAttachments && dropAttachments.length) {
        await droppedAttachments(
          endpoint,
          dropAttachments,
        );
      }

      // ADD ATTACHMENTS TO SERVER
      const addFilterCallback = (
        { attachment: { name } }: IMSOAttachment,
      ) => !serverAttachments.some(
        (serverAttachment: IOutlookGraphAttachmentContent) => (
          (serverAttachment as IOutlookGraphAttachmentContent).name === name
        ),
      );
      const addAttachments: IMSOAttachment[] = localAttachments.filter(addFilterCallback);

      if (addAttachments && addAttachments.length) {
        await addedAttachments(
          endpoint,
          addAttachments,
        );
      }
    }
    return updateMessageData;
  } catch (error) {
    throw new Error(`Error Composing the Message: ${error}`);
  }
};

const addAttachmentToEmail = async (
  file: { url: string; name: string; },
  callback: (asyncResult: Office.AsyncResult<string>) => void,
  isOffice: boolean,
  isReading: boolean,
) => {
  if (!isReading && Office?.context?.mailbox?.item) {
    fetch(file.url)
      .then((resp: any) => {
        if (!resp.ok) return Error(resp.error);
        return resp.blob();
      })
      .then((blob) => {
        const reader = new FileReader();
        reader.onload = () => {
          // NOTE: see MS Office sanitizing note:
          // https://docs.microsoft.com/en-us/javascript/api/outlook/office.messagecompose?view=outlook-js-preview#addFileAttachmentFromBase64Async_base64File__attachmentName__options__callback_
          const b64 = reader.result?.toString() || '';
          const cleanedB64 = b64.replace(/^data:.+;base64,/, '');
          Office.context.mailbox.item.addFileAttachmentFromBase64Async(
            cleanedB64,
            file.name,
            { isInline: false },
            callback,
          );
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        // Office.AsyncResult requires these properties
        callback({
          value: error,
          status: Office.AsyncResultStatus.Failed,
          error,
          asyncContext: '',
          diagnostics: '',
        });
      });
  }
};

const getAddressAsyncCallback = (asyncResult: Office.AsyncResult<Office.EmailAddressDetails[]>) => {
  const finder = (emailAddressDetails: Office.EmailAddressDetails) => (
    emailAddressDetails.emailAddress === asyncResult.asyncContext.email.emailAddress
  );
  if (!asyncResult.error && !asyncResult.value.find(finder)) {
    asyncResult.asyncContext.onComplete([asyncResult.asyncContext.email]);
  } else if (asyncResult.error) {
    // in the case where there was an async error trying to validate that
    // the address wasn't already there let's go ahead and add it just to be safe
    asyncResult.asyncContext.onComplete([asyncResult.asyncContext.email]);
  }
};

const addRecipientToEmail = (
  email: {
    emailAddress: string,
    displayName: string,
  },
  field: 'to' | 'cc' | 'bcc' | 'optionalAttendees' | 'requiredAttendees',
  isReading: boolean,
) => {
  if (!isReading && Office?.context?.mailbox?.item) {
    Office.context.mailbox.item[field].getAsync(
      {
        asyncContext: {
          email,
          onComplete: Office.context.mailbox.item[field].addAsync,
        },
      },
      getAddressAsyncCallback,
    );
  }
};

// Gets the attachment content for each attachment locator in attachments array
const getEmailAttachmentsContent = (
  item: Office.ItemCompose & Office.MessageCompose,
  attachments: Office.AttachmentDetailsCompose[],
) => Promise.all(
  attachments.map(
    (attachment: Office.AttachmentDetailsCompose): Promise<IMSOAttachment> => new Promise(
      (resolve, reject) => {
        item.getAttachmentContentAsync(
          attachment.id,
          {},
          (response: Office.AsyncResult<Office.AttachmentContent>) => {
            if (response.status === Office.AsyncResultStatus.Succeeded) {
              const fvAttachment = {
                attachment: {
                  ...attachment,
                  ...response.value,
                },
                folderId: 0,
              };
              return resolve(fvAttachment);
            }
            return reject(response.error);
          },
        );
      },
    ),
  ),
);

// Gets the Office.AttachmentDetailsCompose from OfficeJS methods on Message.Compose
// OfficeJS functions are not promises but take a callback function and return the value
// to get this to play nice with our try...catch and async function I wrap the OfficeJS
// helper in a Promise that I can then resolve/reject in the callback passed into the OfficeJS
// function. In this case we get the local attachments for a specific item (message compose)
// and then for each attachment we call another OfficeJS helper to get that attachment's content
// this then resolves the promise with a more complete attachment w/ content that we can use to
// send a message in compose flow and/or upload an attachment w/ content to a FV project
const getEmailAttachmentsCompose = (
  item: Office.ItemCompose & Office.MessageCompose,
): Promise<IMSOAttachment[]> => new Promise<IMSOAttachment[]>(
  (resolve, reject) => {
    item.getAttachmentsAsync(
      {},
      async (response: Office.AsyncResult<Office.AttachmentDetailsCompose[]>) => {
        if (response.status === Office.AsyncResultStatus.Succeeded) {
          try {
            if (response.value.length) {
              const attachmentsContent = await getEmailAttachmentsContent(
                item,
                response.value,
              );
              resolve(attachmentsContent);
            } else {
              resolve([]);
            }
          } catch (error) {
            reject(JSON.stringify(error));
          }
        } else {
          reject(response.error);
        }
      },
    );
  },
);

const getAsyncEmailSubject = async (subject: Office.Subject): Promise<string> => (
  new Promise<string>((resolve, reject) => {
    subject.getAsync((asyncResult: Office.AsyncResult<string>) => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        resolve(asyncResult.value);
      } else {
        reject(new Error(`There was an error getting the email subject: ${JSON.stringify(asyncResult.error)}`));
      }
    });
  })
);

const getAsyncEmailField = async (
  recipients: Office.Recipients,
): Promise<IOutlookGraphItemEmailAddress[]> => (
  new Promise<IOutlookGraphItemEmailAddress[]>((resolve, reject) => {
    recipients.getAsync(
      (asyncResult: Office.AsyncResult<Office.EmailAddressDetails[]>) => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          if (asyncResult.value.length > 0) {
            resolve(asyncResult.value.map(({ displayName, emailAddress }) => ({
              emailAddress: {
                name: displayName,
                address: emailAddress,
              },
            })));
          } else {
            // success, but nobody on that field, ie: no bcc
            resolve([]);
          }
        } else {
          reject(new Error(`There was an error getting recipients: ${JSON.stringify(asyncResult.error)}`));
        }
      },
    );
  }));

const getAsyncEmailFrom = (from: Office.From): Promise<IOutlookGraphItemEmailAddress> => (
  new Promise<IOutlookGraphItemEmailAddress>((resolve, reject) => {
    from.getAsync({},
      (asyncResult: Office.AsyncResult<Office.EmailAddressDetails>) => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          resolve({
            emailAddress: {
              name: asyncResult.value.displayName,
              address: asyncResult.value.emailAddress,
            },
          });
        } else {
          reject(new Error(`There was an error getting recipients field: ${JSON.stringify(asyncResult.error)}`));
        }
      });
  }));

const getComposeItemPayload = async (
  m: Office.MessageCompose,
): Promise<IOutlookLocalItemPayload> => (
  new Promise<IOutlookLocalItemPayload>((resolve, reject) => {
    const asyncExecutor = async () => {
      try {
        const emailFrom = await getAsyncEmailFrom(m.from);
        const emailTo = await getAsyncEmailField(m.to);
        const emailCc = await getAsyncEmailField(m.cc);
        const emailBcc = await getAsyncEmailField(m.bcc);
        const emailSubject = await getAsyncEmailSubject(m.subject);
        const emailBody = await getAsyncEmailBody(m.body);
        const emailAttachments = await getEmailAttachmentsCompose(m);
        resolve({
          from: emailFrom,
          toRecipients: emailTo,
          ccRecipients: emailCc,
          bccRecipients: emailBcc,
          subject: emailSubject,
          body: {
            content: emailBody,
            contentType: 'HTML',
          },
          attachments: emailAttachments,
        });
      } catch (error) {
        reject(error);
      }
    };
    asyncExecutor();
  }));

export {
  convertOutlookMessageToFVEmailNote,
  getMessageRecursive,
  addedAttachments,
  droppedAttachments,
  getCurrentToRecipients,
  saveOutlookMessage,
  prepDraftWebApp,
  prepDraftDesktopApp,
  addAttachmentToEmail,
  addRecipientToEmail,
  getAddressAsyncCallback,
  getEmailAttachmentsContent,
  getEmailAttachmentsCompose,
  getAsyncEmailSubject,
  getAsyncEmailField,
  getAsyncEmailFrom,
  getComposeItemPayload,
};
