import React, {
  FC, Fragment, useEffect,
} from 'react';

import useQueryOrgContacts from '../../util-api/useQueryOrgContacts';
import useDebounce from '../../util-hooks/useDebounce';
import useInput from '../../util-hooks/useInput';

import SearchLimitWarning from '../SearchLimitWarning';
import Persona from '../ProjectWorkspace/Persona';
import SecondarySearchBar from '../ProjectWorkspace/SecondarySearchBar';
import IContact from '../../util-api/models/contact';

const css = require('./SearchOrgContacts.module.scss');

interface IProps {
  onSelect: (person: {fullName: string, personId: {native: number}}) => void,
}

const SearchOrgContacts: FC<IProps> = ({
  onSelect,
}: IProps) => {
  // Search and fetch
  const [values, handleValueChange] = useInput({ contactSearchTerm: '' });
  const searchPlaceholderText = 'Search contacts by name or email';

  const {
    getContacts,
    contactsSearchResults: {
      contacts, error, loading, called,
    },
  } = useQueryOrgContacts();

  const debouncedContactSearchTerm = useDebounce(values.contactSearchTerm, 300).trimStart();

  useEffect(() => {
    const reqParams = {
      searchTerm: debouncedContactSearchTerm,
      limit: 1000,
    };
    getContacts(
      reqParams.searchTerm,
      reqParams.limit,
    );
  }, [debouncedContactSearchTerm, getContacts]);

  return (
    <div
      data-test="orgContactsContainer"
      className={css.searchOrgContacts}
    >
      <div className={css.contactsHeader}>
        <SecondarySearchBar
          searchInputName="contactSearchTerm"
          searchPlaceholderText={searchPlaceholderText}
          searchTerm={values.contactSearchTerm}
          handleSearchTermChange={handleValueChange}
          loading={loading}
        />
      </div>
      {contacts && contacts.hasMore && (
      <SearchLimitWarning limit={contacts.limit} />
      )}

      {((contacts && contacts.count > 0) && !error) ? contacts.items.map(({
        fullName,
        pictureUrl,
        primaryEmail,
        personId,
      }: IContact) => {
        const contactId = personId.native;
        return (
          <Fragment key={contactId}>
            <button
              type="button"
              className={css.orgContact}
              onClick={() => onSelect({ fullName, personId })}
              onKeyDown={() => onSelect({ fullName, personId })}
            >
              <Persona
                small
                name={fullName}
                secondLine={primaryEmail}
                avatarUrl={pictureUrl}
              />
            </button>
            <hr className={css.contactsSeparator} />
          </Fragment>
        );
      })
        : (called && !loading && <div className={css.contactsNoResults}>No contacts found</div>)}
      {!contacts && loading && <div className={css.contactsLoading}><Persona loading small /></div>}
    </div>
  );
};

export default SearchOrgContacts;
