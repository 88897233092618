import { useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { ExecutionResult } from 'graphql';

export interface IEmailContact {
  name: string;
  address: string;
}

export interface IAttachment {
  name: string,
  content: string,
  isBase64: boolean,
  contentType?: string,
  folderId?: number,
}

export interface IEmailData {
  subject: string;
  from: IEmailContact,
  to: IEmailContact[];
  cc?: IEmailContact[];
  html: string;
  headers: {
    date: string;
  },
  attachments: IAttachment[];
  emailId: { partner: string | null }
}

interface IEmailResponse {
  noteId: {
    native: number;
  };
}

interface IEmailPayload {
  addEmail: (payload: IEmailData) => Promise<ExecutionResult>;
  loading: boolean;
  error?: ApolloError;
  called: boolean;
  noteId?: number;
}

export class EmailPayload {
  subject: string = '';

  from: IEmailContact = {
    name: '',
    address: '',
  };

  to: IEmailContact[] = [{
    name: '',
    address: '',
  }];

  cc?: IEmailContact[] = [{
    name: '',
    address: '',
  }];

  html: string = '';

  headers: { date: string; } = { date: '' };

  attachments: IAttachment[] = [];

  emailId: { partner: string | null } = { partner: null };
}

const useMutateEmail = (projectId?: number): IEmailPayload => {
  const ADD_EMAIL = gql`
    mutation AddEmail ( $emailData: IEMailData! ) {
      emailNote (id: "${projectId}", emailData: $emailData )
        @rest(
          type: "Email",
          path: "/projects/{args.id}/encodedEmails",
          method: "POST",
          bodyKey: "emailData"
        ) { noteId } 
    }`;

  const [executeQuery, {
    data, error, loading, called,
  }] = useMutation<
    { emailNote: IEmailResponse }, { emailData: IEmailData | any }
  >(
    ADD_EMAIL, { notifyOnNetworkStatusChange: true },
  );

  const addEmail = useCallback(
    (emailData: IEmailData) => {
      // Convert emailData to a JSON string
      const emailString = JSON.stringify(emailData);

      // Encode the string into Base64 using a UTF-8 conversion
      const utf8ToBase64 = (str: string) => {
        return btoa(unescape(encodeURIComponent(str)));
      };

      const encodedEmailData = utf8ToBase64(emailString);

      // Pass the encoded data to executeQuery
      return executeQuery({
        variables: { emailData: { base64Encoding: encodedEmailData } },
      });
    },
    [executeQuery]
  );



  return {
    addEmail,
    error,
    loading,
    called,
    noteId: data?.emailNote?.noteId?.native,
  };
};

export default useMutateEmail;
