import React, { FC, useCallback, useState } from 'react';
import FilevineIcon from '../Taskpane/FilevineIcon';
import SnackbarContext from './SnackbarContext';

const css = require('./Snackbar.module.scss');

interface IProps {
  children: React.ReactElement;
  errorTimeout: number;
  normalTimeout: number;
}

interface ISnackbarMessage {
  text: string;
  isError?: boolean;
  id: string;
  isSticky?: boolean;
}

const Snackbar: FC<IProps> = ({ children, errorTimeout, normalTimeout }) => {
  const [messages, setMessages] = useState<ISnackbarMessage[]>();

  const dismissMessage = (message: ISnackbarMessage) => setMessages(
    (current?: ISnackbarMessage[]) => current?.filter((m: ISnackbarMessage) => m.id !== message.id),
  );

  const addMessage = useCallback((
    text: string,
    isError?: boolean,
    isSticky?: boolean,
  ) => {
    const newMessage: ISnackbarMessage = {
      text, isError, id: `${text}${new Date().getTime()}`, isSticky,
    };

    setMessages((current?: ISnackbarMessage[]) => [
      ...(current || []),
      newMessage,
    ]);

    if (!newMessage.isSticky) {
      setTimeout(
        () => dismissMessage(newMessage),
        isError ? errorTimeout : normalTimeout,
      );
    }
  }, [errorTimeout, normalTimeout]);

  const containerClasses = [
    css.container,
    messages?.length && css.show,
  ].filter(Boolean).join(' ');

  return (
    <>
      <SnackbarContext.Provider value={{
        addMessage,
      }}
      >
        {children}
      </SnackbarContext.Provider>
      <div className={containerClasses}>
        {messages?.map((message: ISnackbarMessage) => (
          <div className={css.message} key={message.id}>
            <div className={css.text}>
              {message.isSticky && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a href="#" role="button" onClick={() => dismissMessage(message)}>
                <FilevineIcon icon="close" className={css.closeIcon} />
              </a>
              )}
              {message.text}
            </div>

            {message.isError && (
              <FilevineIcon icon="exclamation-circle" className={css.errorIcon} />
            )}
          </div>
        )
      )}
      </div>
    </>
  );
};

export default Snackbar;
