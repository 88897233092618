import React, { FC } from 'react';
import { handleMSALRedirect } from '../util-helpers/msal';

const MsalAuth: FC = () => {
  handleMSALRedirect();

  return <div> Microsoft authentication in progress.</div>;
};

export default MsalAuth;
