import React, {
  FC, useEffect, useState,
} from 'react';
import Button from '@fv-components/button';
import useCreateOrgContact, {
  CreateContactSelector,
  IOrgContactCreateStateObj,
} from '../../util-api/useCreateOrgContact';
import useCreateProjectContact from '../../util-api/useCreateProjectContact';
import ErrorMsg from '../../ErrorMsg';
import useQueryContactsMeta, { IContactMetaAllowedValues, IContactsMeta } from '../../util-api/useQueryContactsMeta';

const css = require('./CreateContact.module.scss');

export interface IOrgPerson {
  fullName: string,
  personId: {
    native: number;
  };
}
interface IProps {
  projectId?: number;
  onSuccess?: (person: IOrgPerson) => void;
  onFail?: (e: Error) => void;
  personType?: string;
  formTitle?: string;
  cta?: string;
}

const CreateContact: FC<IProps> = ({
  projectId,
  onSuccess,
  onFail,
  personType,
  formTitle,
  cta,
}: IProps) => {
  const {
    addContact,
  } = useCreateOrgContact();

  const {
    addProjectContact,
  } = useCreateProjectContact();

  const {
    contactsMetaResults,
    getContactsMeta,
  } = useQueryContactsMeta();

  const defaultContactState = {
    firstName: undefined,
    lastName: undefined,
    emailAddress: undefined,
    phoneNumber: undefined,
    personType: undefined,
  };

  const [
    createContact,
    setCreateContact,
  ] = useState<IOrgContactCreateStateObj>(defaultContactState);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  // NOTE: personType is passed in only on creating an org contact
  // this is currently being set in the background to "Client" on
  // the create project flow where an org contact can be created
  // if personType is not passed in then there's no need to fetch
  // the custom contacts meta to get the personType codes for an org
  useEffect(() => {
    if (personType && !contactsMetaResults.called) {
      getContactsMeta();
    }
  }, [contactsMetaResults.called, getContactsMeta, personType]);

  // NOTE: personType only needs to be set on the createContact state
  // when it's passed in currently via the create project create org contact
  // flow where it's hard coded as "Client" and then we use the custom
  // contacts meta to get the org's personType code to use as the payload
  // when creating the org contact, these codes change depending on org
  useEffect(() => {
    if (personType && !createContact.personType
      && contactsMetaResults.called
      && !contactsMetaResults.loading
      && !contactsMetaResults.error
      && contactsMetaResults.items) {
      setCreateContact((prev) => ({
        ...prev,
        personType: contactsMetaResults.items?.find(
          (meta: IContactsMeta) => meta.selector === CreateContactSelector.PersonTypes,
        )?.allowedValues.find(
          (allowed: IContactMetaAllowedValues) => allowed.name === personType,
        )?.value,
      }));
    }
  }, [contactsMetaResults, createContact.personType, personType]);

  const handleSuccess = (person: IOrgPerson) => {
    setCreateContact(defaultContactState);
    if (onSuccess) onSuccess(person);
  };

  const handleFail = (arg: Error) => {
    setErrorMsg(`${arg}`);
    setIsDisabled(true);
    if (onFail) onFail(arg);
  };

  const handleCreateContact = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsDisabled(true);
    if (e.currentTarget.checkValidity() && createContact?.firstName) {
      try {
        const cont = await addContact(createContact);
        const contactId = cont.data?.contact?.personId?.native;
        // NOTE: if projectId is passed in as a prop we'll create a project contact
        // otherwise with no projectId we create an org level contact
        if (projectId && contactId) {
          await addProjectContact(projectId, { orgContactId: { native: contactId } });
        }
        if (!contactId) {
          handleFail(new Error(`Could not create the project contact for ${createContact.firstName}`));
        } else {
          const {
            fullName,
            personId,
          } = cont.data?.contact;
          handleSuccess({ fullName, personId });
        }
      } catch (err) {
        if (createContact.emailAddress
          && createContact.emailAddress.substring(createContact.emailAddress.indexOf('@')).indexOf('.') === -1
        ) {
          setInvalidEmail(true);
          handleFail(new Error('The server rejected the email address. Please ensure your email address is formatted correctly.'));
        } else {
          handleFail(new Error('There was an error creating this contact. Please check your entries.'));
        }
      }
    }
  };

  const updateFirstName = (inputField: React.ChangeEvent<HTMLInputElement>) => {
    const firstName = inputField.currentTarget.value;
    setIsDisabled(!firstName);
    setCreateContact((current) => ({
      ...current,
      firstName,
    }));
  };

  const updateLastName = (inputField: React.ChangeEvent<HTMLInputElement>) => {
    const lastName = inputField.currentTarget.value;
    setCreateContact((current) => ({
      ...current,
      lastName,
    }));
  };

  const updateEmailAddress = (inputField: React.ChangeEvent<HTMLInputElement>) => {
    const emailAddress = inputField.currentTarget.value && inputField.currentTarget.checkValidity()
      ? inputField.currentTarget.value
      : undefined;
    if (createContact.emailAddress !== emailAddress) {
      setInvalidEmail(false);
      setErrorMsg('');
      if (createContact.firstName) setIsDisabled(false);
    }
    setCreateContact((current) => ({
      ...current,
      emailAddress,
    }));
  };

  const updatePhoneNumber = (inputField: React.ChangeEvent<HTMLInputElement>) => {
    const phoneNumber = inputField.currentTarget.value
      ? inputField.currentTarget.value
      : undefined;
    setCreateContact((current) => ({
      ...current,
      phoneNumber,
    }));
  };

  return (
    <form
      className={css.createContactsForm}
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleCreateContact(e)}
      data-test="create-contact-form"
    >
      <h3>{formTitle || 'Create a Contact'}</h3>
      <input
        data-test="create-contact-form-first-name"
        className={`${css.createContact} mdc-menu-surface--anchor`}
        name="firstName"
        placeholder="First Name"
        title="First Name *required"
        defaultValue={createContact?.firstName}
        required
        onChange={updateFirstName}
      />
      <input
        data-test="create-contact-form-last-name"
        className={`${css.createContact} mdc-menu-surface--anchor`}
        name="lastName"
        placeholder="Last Name"
        title="Last Name"
        defaultValue={createContact?.lastName}
        onChange={updateLastName}
      />
      <input
        data-test="create-contact-form-email"
        className={invalidEmail ? `${css.createContact} mdc-menu-surface--anchor ${css.alert}` : `${css.createContact} mdc-menu-surface--anchor`}
        name="emailAddress"
        placeholder="Email Address"
        type="email"
        title="Email address with @ and TLD .com, etc"
        pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
        defaultValue={createContact?.emailAddress}
        onChange={updateEmailAddress}
      />
      <input
        data-test="create-contact-form-phone"
        className={`${css.createContact} mdc-menu-surface--anchor`}
        name="phoneNumber"
        placeholder="Phone Number"
        title="Phone Number"
        type="tel"
        defaultValue={createContact?.phoneNumber}
        onChange={updatePhoneNumber}
      />
      <Button
        disabled={isDisabled}
        unelevated
        data-test="create-contact-submit-button"
        type="submit"
      >
        {cta || 'Create Contact'}
      </Button>
      {errorMsg && (
        <div className={css.createContactErrorText}>
          <ErrorMsg errorText={errorMsg} />
        </div>
      )}
    </form>
  );
};

export default CreateContact;
