import React, { useEffect, useState, FC } from 'react';
import useQueryImage from '../../../util-api/useQueryImage';

const css = require('./Persona.module.scss');

interface IPersonaProps {
  avatarUrl?: string;
  backgroundColor?: string;
  initials?: string;
  name?: string;
  rounded?: boolean;
  secondaryText?: string;
  secondLine?: string | JSX.Element;
  small?: boolean;
  large?: boolean;
  xlarge?: boolean;
  loading?: boolean;
}

export const getInitials = (name: string) => {
  // Used if there is no avatarUrl or initials prop. Or called externally.
  const nameParts = name?.split(' ');
  if (!nameParts?.length) return '';

  let initials = nameParts[0].substring(0, 1).toUpperCase();

  if (nameParts.length > 1) {
    initials += nameParts[nameParts.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

// TODO: Function to get random color from backgroundColors array
//   - remove from array and restock when empty
//   - If no backgroundColor is passed get a random color

// Suggestions for background color options to use with random or loop
export const backgroundColors = [
  'lightblue',
  'grey',
  'midnightblue',
  'lightgreen',
  'darkred',
  'orange',
  'lightpink',
  'teal',
  'mediumpurple',
  'darkgrey',
  'lightskyblue',
  'greenyellow',
  'steelblue',
  'palegreen',
  'mediumvioletred',
  'tan',
  'hotpink',
  'slategrey',
  'mediumslateblue',
  'lightseagreen',
  'orangered',
  'sandybrown',
];

const Persona: FC<IPersonaProps> = ({
  avatarUrl,
  backgroundColor,
  initials,
  name,
  rounded = true,
  secondaryText,
  secondLine,
  small,
  large,
  xlarge,
  loading,
}: IPersonaProps) => {
  const [imageSrc, setImageSrc] = useState<string>();
  const {
    getImage,
    imageResult: {
      image,
      called,
      loading: imgLoading,
    },
  } = useQueryImage();

  // Set img src from avatarUrl or get one from getImage query
  useEffect(() => {
    if (avatarUrl) {
      // If we get a full URL return that url and use it.
      if (avatarUrl?.startsWith('http')) {
        setImageSrc(avatarUrl);
      }

      // Temp backward compatibility to account for OLD image lookup.
      if (avatarUrl?.startsWith('/images')) {
        // Account for bug in api v2 recent projects
        const imageId = avatarUrl.replace('/images//images/', '/images/');

        // Use the old images api end point
        getImage(imageId);
      }
    }
  }, [avatarUrl, getImage]);

  // Set img src from getImage query result
  useEffect(() => {
    if (!imageSrc && image?.ContentType && image.Image && called && !imgLoading) {
      setImageSrc(`data:${image.ContentType};base64, ${image.Image}`);
    }
  }, [avatarUrl, getImage, imageSrc, image, called, imgLoading]);

  return (
    <span className={css.personaContainer}>
      <>
        {imageSrc
          ? (
            <>
              <img
                alt=""
                className={[
                  css.personaAvatar,
                  small ? css.small : '',
                  large ? css.large : '',
                  xlarge ? css.xlarge : '',
                  rounded ? css.rounded : '',
                ].join(' ')}
                style={{ backgroundColor: backgroundColor || '' }}
                src={imageSrc}
              />
            </>
          )
          : (
            <>
              <span
                className={[
                  css.personaAvatar,
                  small ? css.small : '',
                  large ? css.large : '',
                  xlarge ? css.xlarge : '',
                  rounded ? css.rounded : '',
                  loading ? css.loading : '',
                ].join(' ')}
                style={{ backgroundColor: backgroundColor || '' }}
              >
                <span
                  className={[
                    css.personaInitials,
                    small ? css.small : '',
                    large ? css.large : '',
                    xlarge ? css.xlarge : '',
                  ].join(' ')}
                >
                  {initials || (name && getInitials(name)) || ''}
                </span>
              </span>
            </>
          )}
      </>
      {loading
        ? <div className={css.personaNameLoading}>Loading User Details...</div>
        : (
          <div>
            <div className={css.personaDetails}>
              <span className={css.personaName}>{name}</span>
              {secondaryText && <span className={css.personaSecondaryText}>{secondaryText}</span>}
            </div>
            {secondLine && <span className={css.personaSecondaryText}>{secondLine}</span>}
          </div>
        )}
    </span>
  );
};

export default Persona;
