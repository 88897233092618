import React, {
  FC, useCallback, useEffect, useState,
} from 'react';

const css = require('./FilenameInput.module.scss');

interface FilenameErrors {
  hasErrors: boolean;
  errorMessage: string;
}

interface FilenameInputProps {
  filename: string;
  extension: string;
  errors: FilenameErrors;
  setErrors: React.Dispatch<React.SetStateAction<FilenameErrors>>
  onUpdateFilename: (newName: string) => void;
}

const FilenameInput: FC<FilenameInputProps> = ({
  filename,
  extension,
  errors,
  setErrors,
  onUpdateFilename,
}: FilenameInputProps) => {
  const extractFilenameWithoutExtension = useCallback((): string => {
    const filenameSplitterRegex = /(.+?)(\.[^.]*$|$)/;
    const splitFilename = filenameSplitterRegex.exec(filename)!;
    let filenameWithoutExtension = splitFilename[1];
    if (filenameWithoutExtension === extension) filenameWithoutExtension = '';
    return filenameWithoutExtension;
  }, [extension, filename]);

  const validateName = useCallback((newName: string): FilenameErrors => {
    const invalidCharactersRegex = /[<>:"|?*/\\].*/;
    let errorstruct = { hasErrors: false, errorMessage: '' };
    if (invalidCharactersRegex.exec(newName)) {
      errorstruct = { hasErrors: true, errorMessage: 'Filename cannot contain < > : " \\ / | ? or *' };
    }
    if ((`${newName}${extension}`).length > 255) {
      errorstruct = { hasErrors: true, errorMessage: 'Filename is longer than 255 characters.' };
    }
    if (newName.trim() === '') {
      errorstruct = { hasErrors: true, errorMessage: 'Filename cannot be missing.' };
    }
    return errorstruct;
  }, [extension]);

  const [tempFilename, setTempFilename] = useState(extractFilenameWithoutExtension);
  const [hasFocus, setHasFocus] = useState(false);

  useEffect(() => {
    if (!hasFocus) {
      const newName = extractFilenameWithoutExtension();
      setTempFilename(newName);
      setErrors(validateName(newName));
    }
  }, [extractFilenameWithoutExtension, hasFocus, setErrors, validateName]);

  const handleFilenameChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (!hasFocus) return;

    const newName = e.currentTarget.value;

    setErrors(validateName(newName));

    setTempFilename(newName);

    onUpdateFilename(`${newName.trim()}${extension}`);
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && errors.hasErrors) {
      e.preventDefault();
    }
  };

  return (
    <div className={css.component}>
      <div className={css.filenameControl}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className={css.label} htmlFor="filename">
          Filename
        </label>
        <div className={css.filenameDisplay}>
          <input
            className={errors.hasErrors ? css.inputHasErrors : css.input}
            id="filename"
            type="text"
            value={tempFilename}
            onChange={handleFilenameChange}
            data-test="editFilenameInput"
            onKeyPress={handleKeypress}
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
          />
          <div className={`${css.extension}${errors.hasErrors ? ` ${css.inputHasErrors}` : ''}`}>{extension}</div>
        </div>

        {errors.hasErrors
          ? <div className={css.errorMessage} data-test="errorMsg">{errors.errorMessage}</div> : <></>}
      </div>
    </div>
  );
};

export default FilenameInput;
