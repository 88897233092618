import { useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { ExecutionResult } from 'graphql';
import { IProject } from './models/project';

export interface IProjectDataReq {
  projectTypeId: { native: number; };
  clientId: { native: number; };
  projectName?: string;
}

export interface ICreateProjectPayload {
  addProject: (payload: IProjectDataReq) => Promise<ExecutionResult>;
  loading: boolean;
  error?: ApolloError;
  called: boolean;
  project?: IProject;
}

export const ADD_PROJECT = gql`
  mutation AddContact ( $projectData: IProjectDataReq! ) {
    project ( projectData: $projectData )
      @rest(
        type: "Contact",
        path: "/projects",
        method: "POST",
        bodyKey: "projectData"
      ) {
        clientId
        clientName
        firstPrimaryName
        firstPrimaryUsername
        hashtags
        isArchived
        lastActivity
        links
        phaseName
        phaseId
        projectEmailAddress
        projectId
        projectName
        projectOrClientName
        projectUrl
        projectTypeCode
        projectTypeId
        uniqueKey
        rootDocFolderId
      } 
  }`;

const useCreateProject = (): ICreateProjectPayload => {
  const [executeQuery, {
    data,
    error,
    loading,
    called,
  }] = useMutation<
    { project: IProject }, { projectData: IProjectDataReq }
  >(
    ADD_PROJECT, { notifyOnNetworkStatusChange: true },
  );

  const addProject = useCallback(
    (projectData: IProjectDataReq) => executeQuery(
      { variables: { projectData } },
    ), [executeQuery],
  );

  return {
    addProject,
    error,
    loading,
    called,
    project: (!error && data) ? data.project : undefined,
  };
};

export default useCreateProject;
