import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { getConfig } from '../config';

const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID || '',
    redirectUri: `${getConfig().hostUrl}/auth`,
    postLogoutRedirectUri: `${getConfig().hostUrl}/msallogoutsuccess`,
    authority: 'https://login.microsoftonline.com/common',
  },
  cache: {
    cacheLocation: 'localStorage', // needed to avoid "login required" error
    storeAuthStateInCookie: true, // recommended to avoid certain IE/Edge issues
  },
};

// MSAL App Instance
const msalPublicClientAppInstance = new PublicClientApplication(msalConfig);

export default msalPublicClientAppInstance;
