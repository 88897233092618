import React from 'react';
import IProjectContact from '../../../util-api/models/projectContact';
import { getIsComposing } from '../../../util-helpers/office';

import FilevineIcon from '../../FilevineIcon';
import EmailActionButtons from '../EmailActionButtons';
import Persona, { getInitials } from '../Persona';

const css = require('./ContactDetails.module.scss');

interface IContactDetailsProps {
  projectContact: IProjectContact,
}

const ContactDetails = ({
  projectContact,
}: IContactDetailsProps) => {
  const contact = projectContact.orgContact;
  const isComposing = getIsComposing();
  return (
    <div key={contact.personId.native}>
      <div className={css.persona}>
        <Persona
          xlarge
          avatarUrl={projectContact?.orgContact?.pictureUrl}
          initials={getInitials(contact?.fullName)}
        />
      </div>

      <div className={css.fullname}>
        {contact?.fullName || ''}
      </div>

      {projectContact?.role && (
        <div className={css.role}>
          <FilevineIcon icon="user" className={css.userIcon} />
          {projectContact?.role || 'Contact'}
        </div>
      )}

      <div className={css.contactInfoSection}>
        Contact Info
        <hr />
      </div>

      <div className={css.contactInfoContainer}>
        {contact?.emails?.length > 0 && (
          <div className={css.emailSection}>
            {contact.emails.map((email) => (
              <div key={email.emailId.native} className={css.emailRow}>
                <div className={css.emailLabel}>
                  {email.label || 'Email'}
                </div>
                <div className={css.emailAddress}>
                  {email.address}
                </div>
                {isComposing && (
                  <>
                    {/* &nbsp; for empty grid column */}
                    <div>&nbsp;</div>
                    <div className={css.emailActionButtons}>
                      <EmailActionButtons
                        emailAddress={email.address}
                        displayName={email.label ? `${email.label} - ${contact.fullName}` : `${contact.fullName}`}
                      />
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        )}

        {contact?.phones?.length > 0 && (
          <div className={css.phoneSection}>
            {contact.phones.map((phone) => (
              <div key={phone.phoneId.native} className={css.phoneRow}>
                <div className={css.phoneLabel}>
                  {phone.label || 'Phone #'}
                </div>
                <div className={css.phonenumber}>
                  {phone.number}
                </div>
              </div>
            ))}
          </div>
        )}

        {!contact?.emails?.length && !contact?.phones?.length
          && (
          <div>No Information Provided</div>
          )}
      </div>
    </div>
  );
};

export default ContactDetails;
