import React, {
  FC, useCallback, useState, useEffect,
} from 'react';
import ErrorMsg from '../../../../ErrorMsg';
import AttachmentChip from '../../../AttachmentChip';

const css = require('./EmailActivityAttachment.module.scss');

const maxFileSize = 25600000;
const tooMuchErrorMessage = 'The files you selected to send to Filevine exceeded the maximum 25MB.  Please unselect a few files';
const cloudErrorMessage = 'Sorry we do not support cloud attachments at this time';

export interface IEmlInformation {
  name: string,
  isAttached: boolean,
  folderId: number,
  isRenamed?: boolean,
}

export interface IOfficeAttachment {
  attachmentDetails: Office.AttachmentDetails,
  isAttached: boolean,
  folderId: number,
}

export interface IComposeAttachment {
  attachment: Office.AttachmentDetailsCompose;
  folderId: number;
}

interface IEmailActivityAttachmentProps {
  disabled?: boolean;
  onSelectionChange: (selectedIds: string[]) => void;
  isHidden?: boolean;
  attachments: Office.AttachmentDetailsCompose[];
  selectedIds: string[];
  onRenameFile: (id: string, newName: string) => void;
  onFolderDestinationChange: (id: string, folderId: number) => void;
  setEmlInformation: (eml: IEmlInformation) => void;
  isComposeMode?: boolean;
  currentEmailId: string | undefined;
}

const fileSizeReducer = (
  acc: number, attachmentDetail: Office.AttachmentDetailsCompose,
) => acc + attachmentDetail.size;

const cloudFilter = (
  ad: Office.AttachmentDetailsCompose,
) => (ad.attachmentType !== Office.MailboxEnums.AttachmentType.Cloud && !!ad.id);

const EmailActivityAttachment: FC<IEmailActivityAttachmentProps> = (
  {
    isHidden,
    onSelectionChange,
    attachments,
    selectedIds,
    onRenameFile,
    onFolderDestinationChange,
    setEmlInformation,
    isComposeMode,
    currentEmailId,
  }: IEmailActivityAttachmentProps,
) => {
  const getAttachmentsFromIds = useCallback((selectedChipIds: string[]) => attachments?.filter(
      (attachmentDetail: Office.AttachmentDetailsCompose) => selectedChipIds
        .includes(attachmentDetail.id),
  ), [attachments]);

  const selectedFileSize = getAttachmentsFromIds(selectedIds).reduce(fileSizeReducer, 0);
  const hasCloudAttachments = !attachments?.findIndex(
    (
      attachmentDetail: Office.AttachmentDetailsCompose,
    ) => attachmentDetail.attachmentType === Office.MailboxEnums.AttachmentType.Cloud);

  const [selectedAttachments, setSelectedAttachments] = useState(attachments);
  useEffect(() => {
    setSelectedAttachments(attachments.filter((item) => selectedIds.includes(item.id)));
  }, [attachments, selectedIds]);

  const handleSelection = (id: string) => {
    let val = selectedAttachments;
    if (!selectedAttachments.some((a) => a.id === id)) {
      val = selectedAttachments.concat(attachments.filter((a) => a.id === id));
      setSelectedAttachments(val);
    } else {
      val = selectedAttachments.filter((a) => a.id !== id);
      setSelectedAttachments(val);
    }
    onSelectionChange(val.map((att) => att.id));
  };

  const isSelectedAttachment = (id: string):boolean => {
    const val = !!selectedAttachments
      .find((item) => item.id === id);
    return val;
  };

  const [emlFilename, setEmlFilename] = useState<string>((isComposeMode || !Office.context.mailbox.item.subject) ? `no-subject-${Date.now()}.eml` : `${Office.context.mailbox.item.subject}.eml`);
  const [isEmlRenamed, setIsEmlRenamed] = useState<boolean>(false);
  const [isEmlAttached, setIsEmlAttached] = useState<boolean>(true);
  const [folderDestination, setFolderDestination] = useState<number>(0);

  useEffect(() => {
    if (isComposeMode) {
      Office.context.mailbox.item.subject.getAsync((r: Office.AsyncResult<string>) => {
        if (r.status === Office.AsyncResultStatus.Succeeded && r.value) {
          return setEmlFilename(`${r.value}.eml`);
        }
        const noSubjectFilename = `no-subject-${Date.now()}.eml`;
        return setEmlFilename(noSubjectFilename);
      });
    }
    setEmlFilename(Office.context.mailbox.item.subject ? `${Office.context.mailbox.item.subject}.eml` : `no-subject-${Date.now()}.eml`);
  }, [currentEmailId, isComposeMode]);

  useEffect(() => {
    setEmlInformation(
      {
        name: emlFilename,
        isAttached: isEmlAttached,
        folderId: folderDestination,
        isRenamed: isEmlRenamed,
      },
    );
  }, [setEmlInformation, emlFilename, isEmlAttached, folderDestination, isEmlRenamed]);

  const changeEmlFilename = (id: string, name: string) => {
    setEmlFilename(name);
    setIsEmlRenamed(true);
  };

  const toggleEmlAttachment = () => {
    setIsEmlAttached(!isEmlAttached);
  };

  // id is typically required when updating the folder desitination but
  // this for the eml attachment chip and we don't need `id`
  const updateFolderDestination = (id: string, folderId: number) => {
    setFolderDestination(folderId);
  };

  return (
    <>
      {!isHidden && (
        <div className={css.emailIncludeAttachmentsContainer} data-test="email-attachment-container">
          <AttachmentChip
            filename={emlFilename}
            id="emlFile"
            key="emlFile"
            handleSelection={toggleEmlAttachment}
            isSelectedAttachment={() => isEmlAttached}
            onRenameFile={changeEmlFilename}
            onFolderDestinationChange={updateFolderDestination}
            isEml
          />
          {attachments.filter(cloudFilter)
            .map((attachment: Office.AttachmentDetailsCompose) => (
              <AttachmentChip
                filename={attachment.name}
                id={attachment.id}
                key={attachment.id}
                handleSelection={handleSelection}
                isSelectedAttachment={isSelectedAttachment}
                onRenameFile={onRenameFile}
                onFolderDestinationChange={onFolderDestinationChange}
              />
            ))}
          {selectedFileSize > maxFileSize
            && (
            <div className={css.emailIncludeAttachmentsErrorText}>
              <ErrorMsg errorText={tooMuchErrorMessage} contactSupport={false} />
            </div>
            )}
          {hasCloudAttachments
            && (
            <div className={css.emailIncludeAttachmentsErrorText}>
              <ErrorMsg errorText={cloudErrorMessage} contactSupport={false} />
            </div>
            )}
        </div>
      )}
    </>
  );
};

export default EmailActivityAttachment;
