import { createContext } from 'react';
import { IProject, IProjectSearchPayload } from '../util-api/models/project';

export interface ICurrentProject { data: IProject; id: number; }

export interface IProjectContext {
  currentEmailId?: string;
  currentProjectData?: IProject;
  currentProjectId?: number;
  setCurrentProject: React.Dispatch<React.SetStateAction<ICurrentProject | undefined>>;
  projectSearchResults: IProjectSearchPayload
  setProjectSearchResults: React.Dispatch<React.SetStateAction<IProjectSearchPayload>>;
}

export const initialProjectSearchState = {
  called: false,
  loading: false,
  count: 0,
  items: [],
  hasMore: false,
  limit: 0,
};

const ProjectContext = createContext<IProjectContext>({
  setCurrentProject: () => {},
  projectSearchResults: initialProjectSearchState,
  setProjectSearchResults: () => {},
});

export default ProjectContext;
