import React, {
  FC, useState, useEffect, useCallback, useRef,
} from 'react';

// Helpers
import {
  clearAttachmentArray,
  isAttachmentInArray,
  getUpdatedPartnerId,
} from '../../../../util-helpers/common';
import {
  convertOutlookMessageToFVEmailNote,
  getCurrentToRecipients,
  prepDraftDesktopApp,
  prepDraftWebApp,
  saveOutlookMessage,
  getComposeItemPayload,
} from '../../../../util-helpers/composeMode';
import {
  emlAttachmentForFV,
  getIsDesktop,
  getIsWeb,
} from '../../../../util-helpers/office';

// FV API
import useMutateEmail, { EmailPayload } from '../../../../util-api/useMutateEmail';

// Child Components
import DocumentWorkspace from '../../DocumentWorkspace';
import EmailActivityAttachment from '../EmailActivityAttachment';
import EmailActivityInput from '../EmailActivityInput';
import GenericCard from '../../GenericCard';

import { IComposeAttachment, IEmlInformation } from '../EmailActivityAttachment/EmailActivityAttachment';
import graphFetch from '../../../../util-api/msGraph';

// Styles
const css = require('./EmailCompose.module.scss');

const FV_UPLOAD_ERROR = 'The email was successfully sent from Outlook, but failed to upload to Filevine. Please find the message in your sent box and try adding it to Filevine again.';

interface IEmailComposeProps {
  currentUserId: string;
  currentProjectId: number;
  currentEmailId?: string;
}

const EmailCompose: FC<IEmailComposeProps> = (
  {
    currentUserId,
    currentProjectId,
    currentEmailId,
  }: IEmailComposeProps,
) => {
  const [
    attachmentDetails,
    setAttachmentDetails,
  ] = useState<IComposeAttachment[]>();
  const [
    selectedAttachmentDetails,
    setSelectedAttachmentDetails,
  ] = useState<IComposeAttachment[]>([]);
  const [
    emlInformation,
    setEmlInformation,
  ] = useState<IEmlInformation>({
    name: `${Office.context.mailbox.item.subject || `no-subject-${Date.now()}`}.eml`,
    isAttached: true,
    folderId: 0,
  });
  const [
    isCommentPending,
    setIsCommentPending,
  ] = useState(false);
  const [
    isLoading,
    setIsLoading,
  ] = useState(false);
  const [
    isSendEmailSuccess,
    setIsSendEmailSuccess,
  ] = useState(false);
  const [
    isSending,
    setIsSending,
  ] = useState(false);
  const [
    errorMessage,
    setErrorMessage,
  ] = useState<string>();

  // Custom hooks to POST the Outlook message to a Filevine project
  const {
    addEmail,
    called: addEmailToFVCalled,
    noteId,
  } = useMutateEmail(currentProjectId);

  const onCommentAdded = () => {
    setIsCommentPending(false);
    setIsLoading(false);
  };

  enum AttachmentStatus {
    Added = 'added',
    Removed = 'removed',
  }

  const itemRef = useRef(Office.context.mailbox.item);

  const composeError = (displayMessage: string) => {
    setErrorMessage(displayMessage);
    setIsLoading(false);
    setIsSending(false);
  };

  interface Attachment {
    name?: string
  }
  const attachmentChangeHandler = useCallback((args: Office.AttachmentsChangedEventArgs) => {
    if (args.attachmentStatus?.toLocaleLowerCase() === AttachmentStatus.Added) {
      const newAttachment = args.attachmentDetails as unknown as Office.AttachmentDetailsCompose;
      setAttachmentDetails((previousState: any) => (previousState ? [
        ...previousState.filter(
          (att: IComposeAttachment) => att.attachment.id !== newAttachment.id,
        ),
        { attachment: newAttachment, folderId: 0 },
      ] : [{ attachment: newAttachment, folderId: 0 }]));
      if (!isAttachmentInArray((args.attachmentDetails as Attachment)?.name || '') && !newAttachment.isInline) {
        setSelectedAttachmentDetails((previousState: any) => [
          ...previousState,
          { attachment: newAttachment, folderId: 0 },
        ]);
        clearAttachmentArray();
      }
    } else {
      // eslint-disable-next-line max-len
      const removedAttachment = args.attachmentDetails as unknown as Office.AttachmentDetailsCompose;
      setAttachmentDetails((previousState: any) => previousState && [
        ...previousState.filter(
          (att: IComposeAttachment) => att.attachment.id !== removedAttachment.id,
        ),
      ]);
      setSelectedAttachmentDetails((previousState: any) => [
        ...previousState.filter(
          (att: IComposeAttachment) => att.attachment.id !== removedAttachment.id,
        ),
      ]);
    }
  }, [AttachmentStatus.Added]);

  const onSelectionChange = useCallback((selectedIds: string[]) => {
    if (attachmentDetails) {
      setSelectedAttachmentDetails(
        attachmentDetails.filter(
          (attachmentDetail: IComposeAttachment) => (
            selectedIds.includes(attachmentDetail.attachment.id)),
        ),
      );
    }
  }, [attachmentDetails]);

  const onRenameFile = useCallback((id: string, newName:string) => {
    if (attachmentDetails !== undefined) {
      const item = attachmentDetails.find((att) => att.attachment.id === id);
      if (item) {
        item.attachment.name = newName;
      }

      const mutatedAttachments = attachmentDetails.map((att) => (
        att.attachment.id === id ? { ...att, name: newName } : att));
      setAttachmentDetails(mutatedAttachments);
    }
  }, [attachmentDetails]);

  // Send the Outlook message with attachments including .eml file to FV project
  const addEmailToFV = async (email: EmailPayload, id: string) => {
    try {
      const partnerId = getUpdatedPartnerId(id, currentProjectId);
      const message = {
        ...email,
        emailId: {
          ...email.emailId,
          partner: partnerId,
        },
      };
      // POST message to FV API Project Email endpoint
      await addEmail(message);

      setIsSendEmailSuccess(true);
      if (!isCommentPending) {
        setIsLoading(false);
        setIsSending(false);
      }
    } catch (error) {
      // passes the error up to the nested try...catch statement in handleSend
      throw (new Error(FV_UPLOAD_ERROR));
    }
  };

  const handleSend = async (
    commentPending: boolean,
  ) => {
    setIsCommentPending(commentPending);
    setIsSending(true);
    setIsLoading(true);
    setErrorMessage('');

    try {
      // getCurrentToRecipients is a promise that resolves with the current message TO recipients
      // it will reject if there's no recipients on the current item and thus stop the flow and
      // display an error in the UI for to add at least 1 recipient
      await getCurrentToRecipients(Office.context.mailbox.item);

      // save the message
      const id = await saveOutlookMessage(Office.context.mailbox.item);
      const isDesktop = getIsDesktop();
      if (getIsWeb() || isDesktop) {
        const localDraftMessage = await getComposeItemPayload(Office.context.mailbox.item);

        const updateMessageData = isDesktop
          ? await prepDraftDesktopApp(
            localDraftMessage,
            id,
          )
          : await prepDraftWebApp(
            localDraftMessage,
            id,
          );

        const emailForFV = await convertOutlookMessageToFVEmailNote(
          updateMessageData,
          selectedAttachmentDetails,
          localDraftMessage.attachments,
        );

        // If the customer is attaching a .eml file of the email to Filevine
        // GET the EML from Outlook API then create the attachment for FV
        if (emlInformation.isAttached) {
          const emlForFilevine = await emlAttachmentForFV(
            emlInformation,
            emailForFV,
            id,
          );
          emailForFV.attachments.push(emlForFilevine);
        }
        // Send the message via Graph API
        await graphFetch(`/me/messages/${id}/send`, 'POST');

        // Add the sent message to Filevine project
        await addEmailToFV(emailForFV, updateMessageData.Id);
      } else {
        composeError('Unknown Outlook Platform. Please contact support.');
      }
    } catch (error) {
      composeError(`${error}`);
    }
  };

  // Close the Outlook add-in pane after message send
  useEffect(() => {
    if (addEmailToFVCalled
        && !isLoading
        && !isCommentPending
        && isSendEmailSuccess
        && itemRef.current
    ) {
      itemRef.current.close();
    }
  }, [isLoading, addEmailToFVCalled, isCommentPending, isSendEmailSuccess]);

  // populate the list of attachments on load
  useEffect(() => {
    if (Office?.context?.mailbox?.item) {
      Office.context.mailbox.item.addHandlerAsync(
        Office.EventType.AttachmentsChanged,
        attachmentChangeHandler,
      );
      Office.context.mailbox.item.getAttachmentsAsync(
        {},
        (asyncResult: Office.AsyncResult<Office.AttachmentDetailsCompose[]>) => {
          const attachments = asyncResult.value.map(
            (att) => ({ attachment: { ...att }, folderId: 0 }),
          );

          setAttachmentDetails(
            attachments,
          );
          setSelectedAttachmentDetails(
            attachments.filter((att) => !att.attachment.isInline),
          );
        },
      );
    } else {
      setAttachmentDetails([]);
    }

    if (itemRef.current) {
      itemRef.current.removeHandlerAsync(Office.EventType.AttachmentsChanged);
    }
    itemRef.current = Office?.context?.mailbox?.item;
    return () => {
      if (itemRef.current) {
        itemRef.current.removeHandlerAsync(Office.EventType.AttachmentsChanged);
      }
    };
  }, [attachmentChangeHandler, currentEmailId]);

  const onFolderDestinationChange = useCallback((id: string, folderId: number) => {
    const mutated = selectedAttachmentDetails
      .map((item: IComposeAttachment) => (item.attachment.id === id
        ? {
          attachment: { ...item.attachment },
          folderId,
        }
        : item));
    setSelectedAttachmentDetails(mutated);
  }, [selectedAttachmentDetails]);

  return (
    <>
      {!isSendEmailSuccess && (
        <>
          <GenericCard buttonText="Attach Filevine Documents" child={<DocumentWorkspace />} />
          {attachmentDetails && (
            <EmailActivityAttachment
              onSelectionChange={onSelectionChange}
              attachments={attachmentDetails.map((att) => att.attachment)}
              selectedIds={selectedAttachmentDetails
                .map((att: IComposeAttachment) => att.attachment.id)}
              onRenameFile={onRenameFile}
              onFolderDestinationChange={onFolderDestinationChange}
              setEmlInformation={setEmlInformation}
              isComposeMode
              currentEmailId={currentEmailId}
            />
          )}
          <EmailActivityInput
            userId={currentUserId}
            onCommentAdded={onCommentAdded}
            projectId={currentProjectId}
            onAddNote={handleSend}
            isSavingNote={isLoading}
            noteId={noteId}
            isComposing
            refreshComments={() => {}}
            onIsInputFocus={() => {}}
            doneSending={() => isSending}
            errorMessage={errorMessage}
          />
        </>
      )}
      {isSendEmailSuccess && (
        <div className={css.emailComposeSendErrorText}>
          Message sent!
          <br />
          Check your Sent Items folder.
        </div>
      )}
    </>
  );
};

export default EmailCompose;
