import React from 'react';

import { nrPageAction } from '../util-helpers/newrelic';
import FullPageError from '../FullPageError';

interface IErrorBoundaryState { hasError: boolean; error?: Error; }

class ErrorBoundary extends React.Component<{}, IErrorBoundaryState> {
  constructor(props: ChildNode) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: Error) {
    const { message, name, stack } = error;
    nrPageAction('outlookErrorBoundary', { message, name, stack: stack || '' });
  }

  render() {
    const { state: { hasError, error }, props: { children } } = this;
    if (hasError) {
      return <FullPageError errorMessage={`${error?.name}: ${error?.message}`} />;
    }

    return children;
  }
}

export default ErrorBoundary;
