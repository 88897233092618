import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import CryptoJS from 'crypto-js';
import { setOrgId, setUserId, setUserOrgs } from './auth';
import { getIsLocalDevelopment, getIsNonProdEnv } from '../util-helpers/common';

export const percentageUsersToUseFVID = 100;

// Logging function
const log = (type: string, data: any, backgroundColor = 'blue') => {
    if (getIsLocalDevelopment() || getIsNonProdEnv()) {
        console.log(`%c ${type} HTTP:`, `background-color: ${backgroundColor}; color: #ffffff; padding: 20px`, data);
    }
};

// Function to set up interceptors
const setupInterceptors = (instance: any, type: string) => {
    instance.interceptors.request.use(
        (config: any) => {
            log(`${type} Request`, { URL: config.url, payload: config.data }, 'blue');
            return config;
        },
        (error: any) => {
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(
        (response: any) => {
            log(`${type} Response`, { Status: response.status, Body: response.data }, 'green');
            return response;
        },
        (error: any) => {
            if (error.response) {
                log(`${type} Response`, { Status: error.response.status, Body: error.response.data }, 'red');
            }
            return Promise.reject(error);
        }
    );
};

const api = axios.create({});
setupInterceptors(api, 'API');

// ################ OAUTH ########################################

interface User {
    expired: boolean;
    profile: any;
}

export const getEnvironmentSuffix = () => {
    const userDomain = localStorage.getItem('userDomain') || "";
    let environment = "NONPROD";

    if (!userDomain && window.location.host === "outlook.filevine.com") {
        environment = "US";
    }

    if (getIsNonProdEnv()) {
        environment = "NONPROD";
    } else if (userDomain === ".filevineapp.com") {
        environment = "US";
    } else if (userDomain === ".filevineapp.ca") {
        environment = "CA";
    }

    return environment;
};

class FVID {
    static user: User = {
        expired: false,
        profile: undefined
    };

    static authConfig: any = {
        response_type: 'code',
        scope: 'openid profile email phone tenant filevine.v2.api.* theme fv.api.gateway.access fv.auth.tenant.read offline_access',
    }

    static oAuthApi: AxiosInstance;

    static setAuthConfig = () => {
        const environment = getEnvironmentSuffix();

        FVID.authConfig.authority = process.env[`REACT_APP_STS_AUTHORITY_${environment}`] || "";
        FVID.authConfig.client_id = process.env[`REACT_APP_CLIENT_ID_${environment}`] || "";
        FVID.authConfig.redirect_uri = process.env[`REACT_APP_CLIENT_ROOT_${environment}`] + "/callback";
        FVID.authConfig.post_logout_redirect_uri = process.env[`REACT_APP_CLIENT_ROOT_${environment}`] + "/signout";

        FVID.oAuthApi = axios.create({
            baseURL: FVID.authConfig.authority,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        setupInterceptors(FVID.oAuthApi, 'OAUTH');
    }

    static authorize = async () => {
        const codeVerifier = generateCodeVerifier();
        const codeChallenge = await generateCodeChallenge(codeVerifier);
        localStorage.setItem('code_verifier', codeVerifier);

        const params = {
            client_id: FVID.authConfig.client_id,
            redirect_uri: FVID.authConfig.redirect_uri,
            response_type: FVID.authConfig.response_type,
            scope: FVID.authConfig.scope,
            code_challenge: codeChallenge,
            code_challenge_method: 'S256',
        };
        const queryString = createQueryString(params);
        window.location.href = `${FVID.authConfig.authority}/connect/authorize?${queryString}`;
    };

    static exchangeCodeForToken = async (code: string) => {
        const codeVerifier = localStorage.getItem('code_verifier');
        if (!codeVerifier) {
            throw new Error('Code verifier not found');
        }

        FVID.setAuthConfig();

        const data = createQueryString({
            grant_type: 'authorization_code',
            client_id: FVID.authConfig.client_id,
            redirect_uri: FVID.authConfig.redirect_uri,
            code,
            code_verifier: codeVerifier,
        });

        const response = await FVID.oAuthApi.post('/connect/token', data);
        localStorage.setItem("id_token", response.data.id_token);

        return response.data;
    };

    static refreshToken = async (refreshToken: string) => {
        const data = createQueryString({
            grant_type: 'refresh_token',
            client_id: FVID.authConfig.client_id,
            refresh_token: refreshToken,
        });

        const response = await FVID.oAuthApi.post('/connect/token', data);
        return response.data;
    };

    static getUserInfo = async (accessToken: string) => {
        const response = await FVID.oAuthApi.get('/connect/userinfo', {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        FVID.user = response.data.Body;
        await generateHeaders(true);
        return response.data;
    };

    static logout = async () => {
        try {
            const idToken = localStorage.getItem("id_token");
            const postLogoutRedirectUri = FVID.authConfig.post_logout_redirect_uri;

            if (idToken) {
                // Create an iframe for silent logout
                const iframe = document.createElement("iframe");
                iframe.style.display = "none"; // Hide iframe

                // Construct logout URL
                const logoutUrl = `${FVID.authConfig.authority}/connect/endsession?id_token_hint=${encodeURIComponent(idToken)}&post_logout_redirect_uri=${encodeURIComponent(postLogoutRedirectUri)}`;

                iframe.src = logoutUrl;
                document.body.appendChild(iframe);

                setTimeout(() => {
                    document.body.removeChild(iframe);
                }, 5000);
            }

            const useFVID = FVID.getUseFVID();
            localStorage.clear();
            if (useFVID) {
                FVID.setUseFVID(true);
            }
            window.location.assign("/");
        } catch (error) {
            console.error("Silent logout failed:", error);
        }
    };

    static enableFVIDAuth = () => {
        let percentage = percentageUsersToUseFVID;

        // Check if there's a percentage stored in local storage
        const storedPercentage = localStorage.getItem('FVIDAuthPercentage');
        if (storedPercentage !== null) {
            percentage = parseInt(storedPercentage, 10); // Use stored percentage
        }

        console.log("enableFVIDAuth", percentage);

        if (Math.random() < (percentage / 100)) {
            FVID.setUseFVID(true);
            return true;
        }
        FVID.setUseFVID(false);
        return false;
    };

    static setUseFVID = (useFVID: boolean) => {
        localStorage.setItem('useFVID', useFVID.toString());
    };

    static getUseFVID = () => {
        return localStorage.getItem('useFVID') === 'true' || false;
    };

    static getFVIDAccessToken = async () => {
        const accessToken = localStorage.getItem("access_token");
        const refreshToken = localStorage.getItem("refresh_token");

        if (accessToken) {
            // Decode the access token to extract the expiration time
            const tokenParts = accessToken.split('.');
            if (tokenParts.length !== 3) {
                console.error("Invalid token format.");
                FVID.logout();
                return null;
            }

            const payload = JSON.parse(atob(tokenParts[1])); // Decode the payload (base64)
            const now = Math.floor(Date.now() / 1000); // current time in seconds

            // Check if the token is expired
            if (now >= payload.exp && refreshToken) {
                try {
                    const newAccessToken = await FVID.refreshToken(refreshToken);

                    localStorage.setItem("access_token", newAccessToken.access_token);
                    localStorage.setItem("refresh_token", newAccessToken.refresh_token);

                    return newAccessToken.access_token;
                } catch (error) {
                    console.error("Error refreshing token, logging out...", error);
                    FVID.logout(); // Log the user out on error
                    return null;
                }
            }
        } else {
            FVID.logout(); // Log the user out if no token is found
            return null;
        }

        return accessToken;
    };
}

FVID.setAuthConfig();

// ################# HELPER FUNCTIONS ################################
export const generateHeaders = async (includeUserIDandOrg = false):
    Promise<AxiosRequestHeaders> => {
    const accessToken = await FVID.getFVIDAccessToken();
    if (!accessToken) {
        return {};
    }

    const headers: AxiosRequestHeaders = {
        Authorization: `Bearer ${accessToken}`,
        "x-fv-clientip": await getClientIP()
    };

    if (includeUserIDandOrg) {
        const orgsAndUserID = await getUserOrgsAndUserID();
        if (orgsAndUserID && orgsAndUserID.orgs
            && orgsAndUserID.orgs.length > 0
            && orgsAndUserID.ID > 0) {
            setUserId(orgsAndUserID.ID);
            setUserOrgs(orgsAndUserID.orgs);
            setOrgId(orgsAndUserID.orgs[0]?.orgId);
            headers["x-fv-orgid"] = orgsAndUserID.orgs[0];
            headers["x-fv-userid"] = orgsAndUserID.ID;
        }
    }
    return headers;
};

const getClientIP = async () => {
    try {
        const request = await api.get(`https://api.ipify.org/?format=json`);
        return request.data.ip;
    } catch (e) {
        console.log(e);
    }
    return "127.0.0.1";
};

const createQueryString = (params: { [key: string]: string }) =>
    Object.entries(params)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

const base64UrlEncode = (str: string) =>
    str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

// Generate code_verifier
const generateCodeVerifier = () => {
    const codeVerifier = CryptoJS.lib.WordArray.random(32).toString(CryptoJS.enc.Base64);
    return base64UrlEncode(codeVerifier);
};

// Generate code_challenge
const generateCodeChallenge = async (codeVerifier: string) => {
    const hash = CryptoJS.SHA256(codeVerifier);
    return base64UrlEncode(hash.toString(CryptoJS.enc.Base64));
};

export const getUserOrgsAndUserID = async () => {
    const accessToken = await FVID.getFVIDAccessToken();
    if (!accessToken) {
        return null;
    }

    const environment = getEnvironmentSuffix();

    const request = await api.post(`${process.env[`REACT_APP_API_BASE_${environment}`] || ""}/utils/GetUserOrgsWithToken`, null, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        }
    });

    console.log("getUserOrgsAndUserID", request.data);

    return {
        orgs: request.data.orgs, ID: request.data.user.userId.native
    };
};

export default FVID;