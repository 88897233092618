import React, {
  FC, useEffect, useState, useCallback, useRef,
} from 'react';

// Utilities
import LinearProgress from '@fv-components/linear-progress';
import {
  getIsComposing,
} from '../../../util-helpers/office';
import {
  nrPageAction,
  nrCustomAttribute,
  nrInteraction,
  nrInteractionTrace,
} from '../../../util-helpers/newrelic';
import { getCurrentEmailPayload } from '../../../util-helpers/readMode';
import { getUpdatedPartnerId } from '../../../util-helpers/common';

// FV API
import useQueryNote from '../../../util-api/useQueryNote';
import useQueryProjectTeam from '../../../util-api/useQueryProjectTeam';
import useMutateEmail from '../../../util-api/useMutateEmail';

// Child Components
import EmailActivityInput from './EmailActivityInput';
import EmailActivityAttachment from './EmailActivityAttachment';
import EmailActivityHeader from './EmailActivityHeader';
import EmailActivityChat from './EmailActivityChat';
import { IEmlInformation, IOfficeAttachment } from './EmailActivityAttachment/EmailActivityAttachment';

// Styles
const css = require('./EmailActivity.module.scss');

interface IEmailActivityProps {
  currentUserId: string;
  currentEmailId?: string;
  currentProjectId: number;
  scrollContainer: React.RefObject<HTMLDivElement>;
}

const EMAIL_ERROR_TEXT = 'We had trouble adding this email to your Filevine project.';
const FETCHING_PAYLOAD_ERROR_TEXT = 'We had trouble fetching this email from Outlook.';

const EmailActivity: FC<IEmailActivityProps> = (
  {
    currentUserId,
    currentEmailId,
    currentProjectId,
    scrollContainer,
  }: IEmailActivityProps,
) => {
  const prevEmailId = useRef<string>();
  const prevProjectId = useRef<number>();

  const isComposing = getIsComposing();

  const officeAttachments = Office?.context?.mailbox?.item?.attachments;
  const [mutatedAttachments, setMutatedAttachments] = useState<IOfficeAttachment[]>(
    officeAttachments.map((item: Office.AttachmentDetails) => ({
      attachmentDetails: item,
      isAttached: true,
      folderId: 0,
    })),
  );

  // Local State
  const [isInputFocus, setIsInputFocus] = useState(false);
  const [isRefreshingComments, setIsRefreshingComments] = useState(false);
  const [isCommentAdded, setIsCommentAdded] = useState(false);
  const [errorMsg, setErrormsg] = useState('');
  const [isSending, setIsSending] = useState(false);
  // Set the email partner id and keep it updated if the user changes emails
  const [partnerId, setPartnerId] = useState(
    getUpdatedPartnerId(currentEmailId, currentProjectId),
  );
  const [emlInformation, setEmlInformation] = useState<IEmlInformation>();

  // Custom Hooks
  const {
    addEmail,
    loading: isSendingEmail,
    called: addEmailCalled,
  } = useMutateEmail(currentProjectId);

  const {
    getProjectTeam,
    projectTeamSearchResults: { team },
  } = useQueryProjectTeam();

  const {
    getNote,
    note,
    noteLoading,
  } = useQueryNote();

  const noteId = note?.noteId?.native;

  const getTeamMemberName = (id: number) => {
    let author;
    if (team && team.items) {
      author = team.items.find((t) => t.userId.native === id);
    }
    return author ? author.fullname : '';
  };

  useEffect(() => {
    const updatedPartnerId = getUpdatedPartnerId(currentEmailId, currentProjectId);
    setPartnerId(updatedPartnerId);
  }, [currentEmailId, currentProjectId]);

  useEffect(() => {
    if (currentProjectId) {
      getProjectTeam(currentProjectId, '', 1000);
    }
  }, [currentProjectId, getProjectTeam]);

  useEffect(() => {
    const getNoteAsync = async () => {
      await getNote(`@${partnerId}`);
    };
    getNoteAsync();
  }, [partnerId, getNote]);

  useEffect(() => {
    if (currentEmailId !== prevEmailId.current
      || currentProjectId !== prevProjectId.current) {
      setMutatedAttachments(officeAttachments
        .map((item: Office.AttachmentDetails) => ({
          attachmentDetails: item,
          isAttached: !item.isInline,
          folderId: 0,
        })));
    }
    prevEmailId.current = currentEmailId;
    prevProjectId.current = currentProjectId;
  }, [currentEmailId, currentProjectId, officeAttachments]);

  const isDoneSending = (completed: boolean) => {
    if (isSending && completed) {
      setIsSending(false);
    }
  };

  const onCommentsAdded = () => {
    nrInteraction();
    setIsCommentAdded(true);
  };

  const onCommentsRefreshed = () => {
    setIsRefreshingComments(false);
    setIsCommentAdded(false);
    getNote(`@${partnerId}`);
  };

  // entry function for "send" button click
  const onCommentPending = async () => {
    setIsSending(true);
    nrPageAction('start_onCommentPending', { partnerId });
    nrInteractionTrace('onCommentPending', () => nrCustomAttribute('emailAttached', partnerId));

    // putting this here so as to not need to fetch the entire body until sending to FV
    try {
      const payload = await getCurrentEmailPayload(
        partnerId,
        emlInformation!,
        mutatedAttachments
          .filter((item: IOfficeAttachment) => item.isAttached),
      );
      if (payload) {
        const executionResult = await addEmail(payload);
        if (executionResult && !executionResult.errors) {
          getNote(`@${partnerId}`);
          setIsSending(false);
          nrPageAction('end_onCommentPending', { partnerId });
        } else {
          setErrormsg(EMAIL_ERROR_TEXT);
          setIsSending(false);
          nrPageAction('error_onCommentPending', { error: EMAIL_ERROR_TEXT });
        }
      } else {
        setErrormsg(FETCHING_PAYLOAD_ERROR_TEXT);
        setIsSending(false);
      }
    } catch (error) {
      setErrormsg(`${error}` || EMAIL_ERROR_TEXT);
      setIsSending(false);
      nrPageAction('error_onCommentPending', { error: `${error}` || EMAIL_ERROR_TEXT });
    }
  };

  const onSelectionChange = useCallback((selectedIds: string[]) => {
    const mutated = mutatedAttachments.map((item: IOfficeAttachment) => (selectedIds?.includes(
      item.attachmentDetails.id)
      ? { attachmentDetails: item.attachmentDetails, folderId: item.folderId, isAttached: true }
      : { attachmentDetails: item.attachmentDetails, folderId: item.folderId, isAttached: false }));
    setMutatedAttachments(mutated);
  }, [mutatedAttachments]);

  const onRenameFile = useCallback((id: string, newName:string) => {
    const mutated = mutatedAttachments
      .map((item:IOfficeAttachment) => (item.attachmentDetails.id === id
        ? {
          attachmentDetails: { ...item.attachmentDetails, name: newName },
          isAttached: item.isAttached,
          folderId: item.folderId,
        }
        : item));
    setMutatedAttachments(mutated);
  }, [mutatedAttachments]);

  const onFolderDestinationChange = useCallback((id: string, folderId: number) => {
    const mutated = mutatedAttachments
      .map((item:IOfficeAttachment) => (item.attachmentDetails.id === id
        ? {
          attachmentDetails: { ...item.attachmentDetails },
          folderId,
          isAttached: item.isAttached,
        }
        : item));
    setMutatedAttachments(mutated);
  }, [mutatedAttachments]);

  return (
    <div className={css.emailActivityContainer}>
      <EmailActivityHeader
        getTeamMemberName={getTeamMemberName}
        currentEmailId={currentEmailId}
        isComposing={isComposing}
        note={note}
        isInputFocus={isInputFocus}
      />

      {!!noteId
      && (
      <EmailActivityChat
        getTeamMemberName={getTeamMemberName}
        noteId={noteId}
        isRefreshingComments={isRefreshingComments}
        isCommentAdded={isCommentAdded}
        onCommentsRefreshed={onCommentsRefreshed}
        scrollContainer={scrollContainer}
      />
      )}

      <div className={css.emailActivityInputContainer}>
        <div className={css.emailActivityDivider}>
          <LinearProgress
            indeterminate={noteLoading}
            closed={!noteLoading}
          />
        </div>

        <EmailActivityAttachment
          isHidden={noteLoading || !!noteId}
          onSelectionChange={onSelectionChange}
          attachments={mutatedAttachments.map((att) => att.attachmentDetails)}
          onRenameFile={onRenameFile}
          selectedIds={
            mutatedAttachments
              .filter((attachment: IOfficeAttachment) => attachment.isAttached)
              .map((item) => item.attachmentDetails.id)
          }
          onFolderDestinationChange={onFolderDestinationChange}
          setEmlInformation={setEmlInformation}
          currentEmailId={currentEmailId}
        />

        <EmailActivityInput
          projectId={currentProjectId}
          noteId={noteId}
          userId={currentUserId}
          onCommentAdded={onCommentsAdded}
          onAddNote={onCommentPending}
          isSavingNote={isSendingEmail && isSending}
          doneSending={isDoneSending}
          addEmailCalled={addEmailCalled}
          isComposing={false}
          refreshComments={() => setIsRefreshingComments(true)}
          onIsInputFocus={(focus: boolean) => setIsInputFocus(focus)}
          errorMessage={errorMsg}
        />
      </div>
    </div>
  );
};

export default EmailActivity;
