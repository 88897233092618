import { useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import IContact from './models/contact';

interface IOrgContacts {
  count: number;
  offset: number;
  limit: number;
  hasMore: boolean;
  requestedFields: string;
  items: IContact[];
}

interface IContactsResponse {
  contacts: IOrgContacts;
}

export interface IContactsPayload {
  loading: boolean;
  error?: ApolloError;
  contacts?: IOrgContacts;
  called: boolean;
}

export interface IQueryContactsHook {
  contactsSearchResults: IContactsPayload,
  getContacts: (
    searchTerm: string,
    limit?: number,
    personType?: string,
  ) => void,
  getMoreContacts: (
    searchTerm: string,
    limit?: number,
    personType?: string,
  ) => void,
}

export const CONTACTS_QUERY = gql`
  query contactsQuery {
    contacts(searchTerm: $searchTerm, limit: $limit, offset: $offset, personType: $personType)
      @rest(type: "Contacts", path: "/contacts?limit={args.limit}&searchTerm={args.searchTerm}&offset={args.offset}&personType={args.personType}") {
        count
        offset
        limit
        hasMore
        requestedFields
        items
    }
  }`;

const useQueryOrgContacts = (): IQueryContactsHook => {
  const [executeQuery, {
    data, error, loading, called, fetchMore,
  }] = useLazyQuery<IContactsResponse>(
    CONTACTS_QUERY, { notifyOnNetworkStatusChange: true, fetchPolicy: 'cache-and-network' },
  );

  const getContacts = useCallback(
    (
      searchTerm: string,
      limit: number = 50,
      personType: string = '',
    ) => executeQuery(
      {
        variables: {
          searchTerm: encodeURIComponent(searchTerm),
          limit,
          offset: 0,
          personType,
        },
      },
    ), [executeQuery],
  );

  const getMoreContacts = useCallback(
    (
      searchTerm: string,
      limit: number = 50,
      personType: string = '',
    ) => {
      if (data && data?.contacts && data?.contacts?.hasMore && !loading) {
        fetchMore({
          variables: {
            offset: data && data?.contacts ? data.contacts.items.length : 0,
            searchTerm: encodeURIComponent(searchTerm),
            limit,
            personType,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return {
              ...prev,
              contacts: {
                ...prev.contacts,
                items: [
                  ...prev.contacts.items,
                  ...fetchMoreResult.contacts.items,
                ],
                hasMore: fetchMoreResult.contacts.hasMore,
              },
            };
          },
        });
      }
    }, [fetchMore, data, loading],
  );

  return {
    contactsSearchResults: {
      called,
      loading,
      error,
      contacts: data?.contacts,
    },
    getContacts,
    getMoreContacts,
  };
};

export default useQueryOrgContacts;
