import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import gql from 'graphql-tag';
import IContact from './models/contact';

interface IProjectClientResponse {
  client: IContact;
}

interface IProjectClientPayload {
  called: boolean;
  loading: boolean;
  error?: ApolloError;
  client?: IContact;
}

export interface IQueryProjectClientHook {
  projectClientSearchResults: IProjectClientPayload;
  getProjectClient: (projectId: number) => void;
}

export const PROJECTS_QUERY = gql`
  query projectClientQuery {
    client(id: $id)
      @rest(type: "Client", path: "/projects/{args.id}/client") {
        personId
        firstName
        lastName
        fullName
        primaryEmail
        primaryEmailLabel
        pictureUrl
        emails
    }
  }`;

const useQueryProjectClient = (): IQueryProjectClientHook => {
  const [executeQuery, {
    data, error, loading, called,
  }] = useLazyQuery<IProjectClientResponse>(
    PROJECTS_QUERY, { notifyOnNetworkStatusChange: true },
  );

  const getProjectClient = useCallback(
    (id: number) => executeQuery({ variables: { id } }), [executeQuery],
  );

  return {
    projectClientSearchResults: {
      called,
      error,
      loading,
      client: data && data.client,
    },
    getProjectClient,
  };
};

export default useQueryProjectClient;
