import { IEmlInformation } from '../Taskpane/ProjectWorkspace/EmailActivity/EmailActivityAttachment/EmailActivityAttachment';
import graphFetch from '../util-api/msGraph';
import { IAttachment, IEmailData } from '../util-api/useMutateEmail';
import { simplifyHtml } from './common';

const getIsOfficeJs = () => !!Office?.context?.diagnostics?.host;
const getIsOutlook = () => getIsOfficeJs()
  && (Office.context?.diagnostics?.host === Office.HostType.Outlook);
const getIsWord = () => getIsOfficeJs()
  && (Office.context?.diagnostics?.host === Office.HostType.Word);
const getIsExcel = () => getIsOfficeJs()
  && (Office.context?.diagnostics?.host === Office.HostType.Excel);
const getIsPowerPoint = () => getIsOfficeJs()
  && (Office.context?.diagnostics?.host === Office.HostType.PowerPoint);
const getIsComposing = () => getIsOutlook()
  && !!Office.context.mailbox.item && !Office.context.mailbox.item.itemId;
const getIsReading = () => getIsOutlook()
  && !!Office.context.mailbox.item && !!Office.context.mailbox.item.itemId;
const getIsWeb = () => getIsOfficeJs()
  && (Office.context.diagnostics.platform === Office.PlatformType.OfficeOnline);
const getIsDesktop = () => getIsOfficeJs()
  && (Office.context.diagnostics.platform === Office.PlatformType.PC
    || Office.context.diagnostics.platform === Office.PlatformType.Mac);
const getIsMobile = () => getIsOfficeJs()
  && (Office.context.diagnostics.platform === Office.PlatformType.Android
    || Office.context.diagnostics.platform === Office.PlatformType.iOS);
const getIsMacWebkit = () => getIsOfficeJs()
  && (Office.context.diagnostics.platform === Office.PlatformType.Mac
    || Office.context.diagnostics.platform === Office.PlatformType.iOS);
const itemTypeMeetingClass = 'IPM.Schedule.Meeting';
const getIsMeetingClass = () => getIsOutlook() && Office.context?.mailbox?.item?.itemClass
  && `${Office.context.mailbox.item.itemClass}`.includes(itemTypeMeetingClass);
const getIsMeeting = () => getIsOutlook()
  && !!Office.context?.mailbox?.item
  && Office.context?.mailbox?.item?.itemType === 'appointment';
// NOTE: convertToRestId() is not available on the Office class on iOS and Android app
const mobileConvertToRestId = (str: string) => str.replace(/\+/g, '_').replace(/\//g, '-');

const getAsyncAppointmentAttendees = async (
  recipients: Office.Recipients,
): Promise<string[]> => (
  new Promise<string[]>((resolve, reject) => recipients.getAsync(
    (res: Office.AsyncResult<Office.EmailAddressDetails[]>) => {
      if (res.status === Office.AsyncResultStatus.Succeeded) {
        const addresses = res.value.map(
          (address: Office.EmailAddressDetails) => address.emailAddress,
        );
        return resolve(addresses);
      }
      return reject(Error(`An error occurred getting Appointment Attendees: ${JSON.stringify(res.error)}`));
    },
  )));

const getAsyncEmailBody = async (
  body: Office.Body,
  shouldSimplifyHtml?: boolean | false,
): Promise<string> => (
  new Promise<string>((resolve, reject) => {
    body.getAsync('html', { asyncContext: 'body' }, (asyncResult: Office.AsyncResult<string>) => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        resolve(shouldSimplifyHtml ? simplifyHtml(asyncResult.value) : asyncResult.value);
      } else {
        reject(new Error(`There's an error getting the email body: ${JSON.stringify(asyncResult.error)}`));
      }
    });
  }));

const getCurrentEmailId = () => {
  const item = Office?.context?.mailbox?.item;
  // NOTE: convertToRestID() method is not available on mobile clients
  // .replace(/\//g, '-') is basically a polyfill to make the EWS ID a REST ID
  const isMobile = getIsMobile();
  const isMeeting = getIsMeeting();
  let currentEmailId = item?.itemId;

  // Meeting items sometimes do not have the itemId present in compose mode
  if (isMeeting && !currentEmailId) {
    item.getItemIdAsync((id: Office.AsyncResult<string>) => {
      if (id.status === Office.AsyncResultStatus.Succeeded) {
        currentEmailId = id.value;
      }
    });
  }

  if (!isMobile) {
    if (item?.itemId && Office?.context?.mailbox?.convertToRestId) {
      currentEmailId = Office?.context?.mailbox?.convertToRestId(
          item?.itemId,
          Office.MailboxEnums.RestVersion.v2_0,
        ) || `NO-EMAIL-ID-YET-${new Date().getTime()}`;
    }
    if (!currentEmailId && !item?.itemId) {
      currentEmailId = `NO-EMAIL-ID-YET-${new Date().getTime()}`;
    }
  }

  return currentEmailId;
};

const setEmlFilename = (name: string, subject?: string, isRenamed?: boolean): string => {
  if (subject && !isRenamed) {
    return `${subject}.eml`;
  }
  return name;
};

const emlAttachmentForFV = async (
  emlInformation: IEmlInformation,
  emailForFV: IEmailData,
  messageEndpoint: string,
): Promise<IAttachment> => {
  try {
    const messageEmlForFV = await graphFetch(`/me/messages/${messageEndpoint}/$value`, 'GET');
    const emlForFilevine = {
      content: messageEmlForFV,
      isBase64: false,
      name: setEmlFilename(
        emlInformation.name,
        emailForFV.subject,
        emlInformation.isRenamed,
      ),
      folderId: emlInformation.folderId,
    };
    return emlForFilevine;
  } catch (error) {
    throw new Error(`${error}`);
  }
};

export {
  getIsComposing,
  getIsDesktop,
  getIsMobile,
  getIsMeetingClass,
  getIsMeeting,
  getIsOfficeJs,
  getIsOutlook,
  getIsReading,
  getIsWeb,
  getIsWord,
  getIsExcel,
  getIsPowerPoint,
  getCurrentEmailId,
  mobileConvertToRestId,
  getAsyncAppointmentAttendees,
  getAsyncEmailBody,
  setEmlFilename,
  emlAttachmentForFV,
  getIsMacWebkit,
};
