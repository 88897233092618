import React, {
  FC, useEffect, useState, useCallback, useRef,
} from 'react';
import { ExecutionResult } from 'apollo-link';
import Button from '@fv-components/button';
import LinearProgress from '@fv-components/linear-progress';
import useMutateComment from '../../../../util-api/useMutateComment';
import useInput from '../../../../util-hooks/useInput';
import ErrorMsg from '../../../../ErrorMsg';
import TeamLookupMenu, { IUserSearchState } from '../../../../Taskpane/TeamLookupMenu';
import RefreshButton from '../../../../Taskpane/ProjectWorkspace/RefreshButton/RefreshButton';

const css = require('./DocActivityInput.module.scss');

interface IDocActivityInputProps {
  projectId: number;
  userId: string;
  noteId?: number;
  isSavingNote?: boolean;
  onCommentAdded: VoidFunction;
  onAddNote: (commentText: string) => void;
  refreshComments: VoidFunction;
  onIsInputFocus: (isInputFocus: boolean) => void;
  doneSending: (isSending: boolean) => void;
  parentErrorMessage?: string;
}

const DocActivityInput: FC<IDocActivityInputProps> = (
  {
    noteId,
    userId,
    onCommentAdded,
    projectId,
    isSavingNote,
    onAddNote,
    refreshComments,
    onIsInputFocus,
    doneSending,
    parentErrorMessage,
  }: IDocActivityInputProps,
) => {
  const [values, handleValueChange] = useInput({ docComment: '' });

  const {
    // error: commentErr, error doesn't update on mutation bug fixed in apollo v3 beta
    addComment,
    loading: isSendingComment,
  } = useMutateComment();

  const [btnDisabled, setBtnDisabled] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(parentErrorMessage);

  const handleInputFocus = useCallback(() => {
    onIsInputFocus(true);
  }, [onIsInputFocus]);

  const handleInputBlur = useCallback(() => {
    onIsInputFocus(false);
  }, [onIsInputFocus]);

  const handleAddNoteOrComment = useCallback(() => {
    const comment = values.docComment;
    handleValueChange({
      target: {
        name: 'docComment',
        value: '',
      },
    });

    doneSending(true);
    setIsSendingMessage(true);

    setBtnDisabled(true);

    setErrorMessage('');

    if (!noteId) {
      onAddNote(comment);
      return;
    }

    if (userId && noteId && comment.length) {
      const commentData = {
        body: comment,
        authorId: { native: +userId },
      };
      // add the comment to FV Note
      addComment(noteId, commentData)
        .then((executionResult: ExecutionResult) => {
          if (!executionResult.errors) {
            onCommentAdded();
          }
          setBtnDisabled(false);
        })
        .catch((e: Error) => {
          setErrorMessage(e?.message);
        });
    }
  }, [
    values.docComment,
    handleValueChange,
    doneSending,
    noteId,
    userId,
    onAddNote,
    addComment,
    onCommentAdded,
  ]);

  const isSending = isSavingNote || isSendingComment || isSendingMessage;

  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (parentErrorMessage) {
      setErrorMessage(parentErrorMessage);
      setBtnDisabled(false);
      setIsSendingMessage(false);
    }
  }, [setErrorMessage, parentErrorMessage, isSending, btnDisabled, noteId, doneSending]);

  // Send comment on shift enter
  // Disable when already sending or no text
  useEffect(() => {
    const submitOnShiftEnter = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && e.shiftKey) {
        e.stopPropagation();
        e.preventDefault();
        if (!isSending) {
          handleAddNoteOrComment();
        }
      }
    };
    document.addEventListener('keydown', submitOnShiftEnter);

    // reset btnDisabled if not currently sending
    if (isSending && btnDisabled && noteId) {
      setBtnDisabled(false);
      doneSending(isSending);
      setIsSendingMessage(false);
    }

    return () => {
      document.removeEventListener('keydown', submitOnShiftEnter);
    };
  }, [
    handleAddNoteOrComment,
    isSending,
    btnDisabled,
    setBtnDisabled,
    doneSending,
    noteId,
  ]);

  // @username Menu and input stuff
  const getSendButtonText = (): string => {
    if (!noteId && !values.docComment) {
      return 'Attach To Note';
    }
    if (!noteId) {
      return 'Attach With Comment';
    }
    return 'Add Comment';
  };

  const handleUserPicked = (username?: string, userSearch?: IUserSearchState) => {
    if (username && userSearch && userSearch.endPos && userSearch.startPos) {
      const value = `${values.docComment.substring(0, userSearch.startPos)}${username}${values.docComment.substring(userSearch.endPos)}`;
      handleValueChange({ target: { name: 'docComment', value } });
      if (inputRef && inputRef.current) {
        inputRef.current.selectionEnd = userSearch.startPos + username.length;
      }
    }
  };

  return (
    <>
      <TeamLookupMenu
        inputRef={inputRef}
        currentProjectId={projectId}
        onSelect={handleUserPicked}
        inputValue={values.docComment}
      />

      {!!errorMessage && (
        <div className={css.docSendInputErrorText}>
          <ErrorMsg errorText={errorMessage} />
        </div>
      )}

      <textarea
        ref={inputRef}
        className={css.docSendInputTextArea}
        data-test="comment-input"
        placeholder="Add a comment or task."
        name="docComment"
        value={values.docComment}
        onChange={handleValueChange}
        disabled={isSending}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      />
      <div className={css.docSendButtonsRow}>
        <Button
          disabled={btnDisabled}
          unelevated
          className={css.docSendInputSendBtn}
          data-test="activity-send-button"
          onClick={handleAddNoteOrComment}
        >
          {getSendButtonText()}
        </Button>
        {noteId && (
          <div title="Refresh Comments">
            <RefreshButton onRefresh={refreshComments} />
          </div>
        )}
      </div>

      {isSending && (
        <LinearProgress
          indeterminate={isSending}
          closed={!isSending}
          className={css.docSendInputSending}
        />
      )}
    </>
  );
};

export default DocActivityInput;
