import React, { FC } from 'react';
import { handleMSALLogoutSuccess } from '../util-helpers/msal';

const MsalLogout: FC = () => {
  handleMSALLogoutSuccess();

  return <div> Microsoft authentication logout successful. You may close this window.</div>;
};

export default MsalLogout;
