import { useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import gql from 'graphql-tag';
import { useCallback } from 'react';

export type TeamTypes = 'Follower' | 'Collaborator' | 'Guest';

export interface ITeamMember {
  userId: {
    native: number;
  };
  username: string;
  email: string;
  fullname: string;
  isPrimary: boolean;
  isAdmin: boolean;
  level: TeamTypes;
  pictureUrl: string;
  isFirstPrimary: boolean;
  isOnlyPrimary: boolean;
}

interface IProjectTeam {
  count: number;
  offset: number;
  limit: number;
  hasMore: boolean;
  requestedFields: string;
  items: ITeamMember[];
}

interface IProjectTeamResponse {
  team: IProjectTeam;
}

interface IProjectTeamPayload {
  loading: boolean;
  error?: ApolloError;
  team?: IProjectTeam;
  called: boolean;
}

export interface IQueryProjectTeamHook {
  projectTeamSearchResults: IProjectTeamPayload;
  getProjectTeam: (projectId: number, searchTerm: string, limit?: number) => void;
  getMoreProjectTeam: (projectId: number, searchTerm: string, limit?: number) => void;
}

export const TEAM_QUERY = gql`
  query teamQuery {
    team(id: $id, searchTerm: $searchTerm, limit: $limit, offset: $offset)
      @rest(type: "Team", path: "/projects/{args.id}/team?limit={args.limit}&searchTerm={args.searchTerm}&offset={args.offset}") {
        count
        offset
        limit
        hasMore
        requestedFields
        items
    }
  }`;

const useQueryProjectTeam = (): IQueryProjectTeamHook => {
  const [executeQuery, {
    data, error, loading, called, fetchMore,
  }] = useLazyQuery<IProjectTeamResponse>(
    TEAM_QUERY, { notifyOnNetworkStatusChange: true, fetchPolicy: 'network-only' },
  );

  const getProjectTeam = useCallback(
    (id: number, searchTerm: string, limit: number = 50) => executeQuery(
      {
        variables: {
          id, searchTerm: encodeURIComponent(searchTerm), limit, offset: 0,
        },
      },
    ), [executeQuery],
  );

  const getMoreProjectTeam = useCallback(
    (projectId: number, searchTerm: string, limit: number = 50) => {
      if (fetchMore && data && data.team && data.team.items) {
        fetchMore({
          variables: {
            offset: data && data.team ? data.team.items.length : 0,
            projectId,
            searchTerm: encodeURIComponent(searchTerm),
            limit,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return {
              ...prev,
              team: {
                ...prev.team,
                items: [
                  ...prev.team.items,
                  ...fetchMoreResult.team.items,
                ],
                hasMore: fetchMoreResult.team.hasMore,
              },
            };
          },
        });
      }
    }, [fetchMore, data],
  );

  return {
    projectTeamSearchResults: {
      error,
      loading,
      team: data && data.team,
      called,
    },
    getProjectTeam,
    getMoreProjectTeam,
  };
};

export default useQueryProjectTeam;
