import { IEmlInformation, IOfficeAttachment } from '../Taskpane/ProjectWorkspace/EmailActivity/EmailActivityAttachment/EmailActivityAttachment';
import { IOutlookGraphAttachmentContent } from '../util-api/models/outlookItem';
import graphFetch from '../util-api/msGraph';
import { EmailPayload, IAttachment, IEmailContact } from '../util-api/useMutateEmail';
import {
  emlAttachmentForFV,
  getAsyncEmailBody,
  getIsMobile,
  mobileConvertToRestId,
} from './office';
import { nrPageAction } from './newrelic';

const formatMSEmailArray = (emails: Office.EmailAddressDetails[]): IEmailContact[] => (
  emails.map(({ displayName, emailAddress }: Office.EmailAddressDetails) => ({
    name: displayName || '',
    address: emailAddress || '',
  }))
);

const messageItemId = (): string => {
  if (getIsMobile()) {
    return Office.context.mailbox.item.itemId;
  }

  return Office.context.mailbox.convertToRestId(
    Office.context.mailbox.item.itemId,
    Office.MailboxEnums.RestVersion.v2_0,
  );
};

// on iOS and Andoird Outlook apps the item.getAttachmentContentAsync method doesn't work
// on web and desktop the getAttachmentContentAsync method makes multiple XHR calls
// We call the API to get the attachments w/ base64 ContentBytes for the attachments
// then loop over our mutated attachments array and apply the ContentBytes to the matching
// attachment for upload to FV project
const getEmailAttachmentsGraph = (
  attachments: IOfficeAttachment[],
): Promise<IAttachment[]> => new Promise((resolve, reject) => {
  const asyncFunc = async () => {
    try {
      // use the Graph API to fetch the attachments
      const getMessageAttachmentsDataGraph = await graphFetch(`/me/messages/${messageItemId()}/attachments`, 'GET');
      if (getMessageAttachmentsDataGraph.value.length) {
        // match the local attachments to the server attachments
        const payloadAttachments = Promise.all(attachments.map(
          (la: IOfficeAttachment): Promise<IAttachment> => new Promise((res, rej) => {
            let serverAttachmentMatch: IOutlookGraphAttachmentContent | undefined;
            try {
              serverAttachmentMatch = getMessageAttachmentsDataGraph.value.find(
                (sa: IOutlookGraphAttachmentContent) => (
                  la.attachmentDetails.id === sa.id
                  || mobileConvertToRestId(la.attachmentDetails.id) === sa.id
                ),
              );
            } catch (err) {
              nrPageAction('noAttachmentContentMatching', err);
            }
            if (serverAttachmentMatch) {
              const payloadAttachmentForFV = {
                name: la.attachmentDetails.name,
                content: serverAttachmentMatch.contentBytes,
                isBase64: true,
                folderId: la.folderId,
              };

              return res(payloadAttachmentForFV);
            }

            nrPageAction('noAttachmentContentMatching', { ob: JSON.stringify(la) });
            return rej(new Error(`No attachment matching ${la.attachmentDetails.name}`));
          }),
        ));

        const formattedAttachments = await payloadAttachments;
        resolve(formattedAttachments);
      } else {
        resolve([]);
      }
    } catch (error) {
      reject(error);
    }
  };
  asyncFunc();
});

const getCurrentEmailPayload = async (
  partnerId: string,
  emlInformation: IEmlInformation,
  attachments?: IOfficeAttachment[],
): Promise<EmailPayload> => new Promise<EmailPayload>((resolve, reject) => {
  // Init required fields with safe values to fall back if needed
  const asyncExecutor = async () => {
    try {
      const emailPayload = new EmailPayload();
      const subject = Office.context.mailbox.item.subject || emailPayload.subject;
      const html = await getAsyncEmailBody(Office.context.mailbox.item.body, true);
      const from = formatMSEmailArray([Office.context.mailbox.item.from]);
      const payload = {
        ...emailPayload,
        subject,
        from: from && from.length > 0 ? from[0] : emailPayload.from,
        to: formatMSEmailArray(Office.context.mailbox.item.to) || emailPayload.to,
        cc: formatMSEmailArray(Office.context.mailbox.item.cc) || emailPayload.cc,
        html,
        headers: {
          ...emailPayload.headers,
          date: Office.context.mailbox.item.dateTimeCreated.toString()
            || emailPayload.headers.date,
        },
        emailId: {
          ...emailPayload.emailId,
          partner: partnerId,
        },
      };
      if (attachments?.length) {
        const downloadedAttachments = await getEmailAttachmentsGraph(attachments);
        payload.attachments = [...downloadedAttachments, ...payload.attachments];
      }
      if (emlInformation.isAttached) {
        const endpoint = messageItemId();
        const emlAttachment = await emlAttachmentForFV(
          emlInformation,
          payload,
          endpoint,
        );
        payload.attachments.push(emlAttachment);
      }
      resolve(payload);
    } catch (error) {
      reject(error);
    }
  };
  asyncExecutor();
});

export {
  formatMSEmailArray,
  getEmailAttachmentsGraph,
  getCurrentEmailPayload,
};
