import React, { FC, useEffect, useState } from 'react';
import { I18n } from 'aws-amplify';
import {
  Authenticator, Greetings, SignIn, ConfirmSignIn,
} from 'aws-amplify-react';

import Button from '@fv-components/button';
import LinearProgress from '@fv-components/linear-progress';

import {
  getConfig,
  getTenantConfig,
  setTenantConfig,
  setUserTenant,
  getUserTenant,
  envShards,
  ITenantConfig,
  setUserDomain,
  getUserDomain,
} from '../../config';
import FilevineIcon from '../../Taskpane/FilevineIcon';
import ErrorMsg from '../../ErrorMsg';
import EphemeralId from '../../EphemeralId';
import { handleAmplifyConfigure, setSAMLAuth } from '../auth';

const css = require('./Login.module.scss');

const { hostUrl } = getConfig();

const authTheme = {
  sectionHeader: { display: 'none' },
  inputLabel: { display: 'none' },
  input: {
    border: '1px solid #ccc',
    borderRadius: '6px',
    marginBottom: '20px',
    padding: '10px 16px',
    width: '100%',
    boxSizing: 'border-box',
    height: '46px',
    lineHeight: '1.33',
    fontSize: '15px',
    fontWeight: '300',
    color: '#555',
  },
  button: {
    padding: '10px 16px',
    fontSize: '18px',
    lineHeight: '1.33',
    borderRadius: '6px',
    width: '100%',
    color: '#fff',
    border: '1px solid #005776',
    outline: 'none !important',
    background: '#005776',
    marginBottom: '10px',
  },
  toast: {
    color: '#860A0E',
    textAlign: 'center',
    fontSize: '.85em',
    marginBottom: '10px',
  },
};

// Change input placeholders and error messages
export const authLabels = {
  en: {
    'Enter your username': 'Email',
    'Enter your password': 'Password',
    'Username cannot be empty': 'Email cannot be empty.',
    'Incorrect username or password': 'Invalid email or password.',
    'CUSTOM_AUTH is not enabled for the client.': 'Password cannot be empty.',
    Code: 'Enter Verification Code',
  },
};
I18n.setLanguage('en');
I18n.putVocabularies(authLabels);

const LoginCognito: FC = () => {
  // Auth state
  const [isSignedIn, setIsSignedIn] = useState(false);
  const handleAuthStateChange = async (state: string) => {
    if (state === 'signedIn') {
      setIsSignedIn(true);
      if (Office?.context?.host) {
        Office.context.ui.messageParent(JSON.stringify(localStorage));
      }
    }
  };

  // Tenant Config
  interface IConfigState {
    configError?: string;
    tenantConfig?: ITenantConfig;
    tenantKey: string;
    loading: boolean;
  }

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<IConfigState>({
    configError: undefined,
    tenantConfig: undefined,
    tenantKey: getUserTenant(),
    loading: false,
  });

  const appClientID = state.tenantConfig?.appClientID;

  const handleIDPClick = (idp: string) => {
    setSAMLAuth(idp);
    const authorizationEndpoint = state?.tenantConfig?.authorizationEndpoint;
    const cognitoRedirectUrl = appClientID && authorizationEndpoint && hostUrl && `${authorizationEndpoint}?identity_provider=${idp}&redirect_uri=${hostUrl}/success&response_type=code&client_id=${appClientID}&scope=email%20openid%20profile`;

    if (cognitoRedirectUrl) {
      window.location.assign(cognitoRedirectUrl);
    }
  };

  useEffect(() => {
    const getConf = async () => {
      setLoading(true);
      const tc = await getTenantConfig();
      // Set config and Init Amplify if config returned
      if (tc?.appClientID && tc?.cognitoUserPoolID) {
        await handleAmplifyConfigure();
        setState((s: IConfigState) => ({
          ...s,
          tenantConfig: tc,
        }));
      } else {
        setUserTenant('');
        setState((s: IConfigState) => ({
          ...s,
          configError: tc.error || '',
        }));
      }
      setLoading(false);
    };

    if (state.tenantKey && !appClientID) {
      getConf();
    }
  }, [state.tenantKey, appClientID]);

  // Update tenant input field value
  const [tenantInputValue, setTenantInputValue] = useState('');
  const handleTenantInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((s: IConfigState) => ({
      ...s,
      configError: undefined,
    }));
    setTenantInputValue(e.target.value);
  };

  // Handle tenant picked either from shard button or input enter
  const handleTenantChange = async (tenant?: string): Promise<void> => {
    const tenantName = tenant?.toLowerCase() || tenantInputValue.toLowerCase();
    setUserTenant(tenantName);
    setState((s: IConfigState) => ({
      ...s,
      configError: undefined,
      tenantKey: tenantName,
    }));
  };

  const handleTenantClear = () => {
    setState((s: IConfigState) => ({
      ...s,
      tenantConfig: undefined,
      tenantKey: '',
    }));
    setSAMLAuth('');
    setTenantConfig('');
    setTenantInputValue('');
    setUserTenant('');
  };

  useEffect(() => {
    if (!getUserDomain()) {
      setUserDomain(envShards[0]);
    }
  }, []);

  const handleDomainChange = (userDomain: string) => {
    setUserDomain(userDomain);
  };

  const {
    filevineTenantUrl, filevineBaseUrl,
  } = getConfig();

  return (
    <div className={css.container}>
      <div className={css.content}>
        <EphemeralId />
        <img
          alt="Filevine"
          className={css.logo}
          height="66"
          src="assets/fv-logo.svg"
        />
        {!state.tenantConfig
          ? (
            <div className={css.shardOptions}>
              <div>Enter your Filevine Login Domain</div>
              <div className={css.customOption}>
                <div>
                  <input
                    id="customShard"
                    className={css.customInputTeam}
                    onChange={
                      (e: React.ChangeEvent<HTMLInputElement>) => handleTenantInputValueChange(e)
                    }
                    onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>) => {
                      if (e.key === 'Enter') {
                        handleTenantChange();
                      }
                    }}
                  />
                  <select
                    className={css.customInputDomain}
                    defaultValue={getUserDomain()}
                    onChange={
                    (e: React.ChangeEvent<HTMLSelectElement>) => handleDomainChange(e.target.value)
                  }
                  >
                    {envShards.map((url: string) => (
                      <option
                        key={url}
                        value={url}
                      >
                        {url}
                      </option>
                    ))}
                  </select>
                </div>
                <Button
                  raised
                  className={css.customBtn}
                  disabled={!tenantInputValue}
                  onClick={() => handleTenantChange()}
                >
                  <FilevineIcon icon="chevron-right" />
                </Button>
              </div>
              {loading && (
                <LinearProgress indeterminate />
              )}
              {state.configError
                && (
                <div className={css.customNotFound}>
                  <ErrorMsg errorText={state.configError} />
                </div>
                )}
            </div>
          )
          : (
            <>
              {!isSignedIn && (
              <Button
                className={css.clearShardBtn}
                onClick={() => handleTenantClear()}
              >
                <FilevineIcon icon="chevron-left" className={css.clearShardBtnIcon} />
                {filevineTenantUrl}
              </Button>
              )}

              <Authenticator
                hideDefault
                theme={authTheme}
                onStateChange={handleAuthStateChange}
              >
                <SignIn />
                <Greetings />
                <ConfirmSignIn />
              </Authenticator>

              {!isSignedIn && (
                <>
                  <a
                    className={css.forgotLink}
                    href={`${filevineBaseUrl}/Account/ForgotPassword`}
                  >
                    Forgot password?
                  </a>

                  {state.tenantConfig.identityProviders?.map(({ name }) => (
                    <Button
                      outlined
                      className={css.IDPBtn}
                      onClick={() => handleIDPClick(name)}
                      key={name}
                    >
                      {` Sign in with ${name}`}
                    </Button>
                  ))}
                </>
              )}
            </>
          )}
      </div>
    </div>
  );
};

export default LoginCognito;
