import React, { FC, useEffect } from 'react';
import LinearProgress from '@fv-components/linear-progress';
import FVID from '../FVID';

const css = require('./Login.module.scss');

const LogoutFVID: FC = () => {
    useEffect(() => {
        FVID.logout();
    }, []);

    return <>
        <div className={css.container}>
            <div className={css.content} style={{ paddingTop: 50 }}>
                <div className={css.fvidLoginBox} style={{ height: 250 }}>
                    <img
                      alt="Filevine"
                      height="45"
                      src="assets/fv-logo.svg"
                      style={{ marginLeft: 50, marginBottom: 30 }}
                    />
                    <LinearProgress />
                    <>
                        <p>Signing out...</p>
                    </>
                </div>
            </div>
        </div>
    </>;
};

export default LogoutFVID;